import { Button, ButtonProps } from "antd";
import styled, { css } from "styled-components/macro";
import { colors, fonts } from "@src/config/styles/variables";

export const StandardButton = styled(Button)<ButtonProps>`
  height: auto !important;

  span {
    font-family: ${fonts.secondary};
    font-weight: 700;
    line-height: 1.5;
  }

  &.ant-btn-link {
    
    span {
      color: ${colors.primary};
    }
  }

  ${({size}: ButtonProps) => {
    switch (size) {
      case 'small':
        return css`
          padding: 8px 16px !important;
          span {
            font-size: 14px;
          }
        `
      case 'large':
        return css`
          padding: 12px 24px !important;
          span {
            font-size: 18px;
          }
        `
      default:
        return css`
          padding: 10px 20px !important;
          span {
            font-size: 16px;
          }
        `
    }
  }}

`
