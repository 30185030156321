import { paragon } from '@useparagon/connect';
import { IntegrationPlatformItem, IntegrationPlatformProps } from "../integrations";
import { message } from 'antd';

export const IntegrationGoogleDrive = (props: IntegrationPlatformProps) => {

  const connectToDrive = async () => {
    paragon.connect("googledrive", {
      onSuccess: async () => {
        if (props.onConnect) {
          await props.onConnect('googledrive');
          message.success('Connected to Google Drive!');
        }
      },
      onError: (error) => {}
    });
  }

  return (
    <IntegrationPlatformItem
      name="googledrive"
      label="Google Drive"
      isEnabled={props.paragonUser?.integrations['googledrive']?.enabled || false}
      connect={connectToDrive}
    />
  )
}