import { SlateInputElement } from "@src/components/config/config-editor.types";
import { Editor, Element, Path, Transforms } from "slate";
import { PromptTemplateInput } from "./prompts.types";
import { Node } from "slate";

export const withInputs = (editor: Editor) => {
  const { isVoid, isInline, insertNode, insertNodes } = editor;

  editor.isInline = (element: Element) => {
    return element.type === 'input' ? true : isInline(element);
  }

  editor.isVoid = (element: Element) => {
    return element.type === 'input' ? true : isVoid(element)
  }

  editor.insertInput = (input: PromptTemplateInput) => {
    insertNode({
      type: 'input',
      input: input,
      children: [{text: ''}],
    })
    if (editor.selection?.anchor) {
      const insertedNodePath = Path.parent(editor.selection.anchor.path);
      const textNode = { text: ' ' };
      const textNodePath = Path.next(insertedNodePath);
      insertNodes(textNode, { at: textNodePath });
    }

    let insertedNode;
    if (editor.selection?.anchor) {
      insertedNode = Node.get(editor, Path.parent(editor.selection.anchor.path));
    }
    return insertedNode;
  }

  editor.removeInput = (input: PromptTemplateInput) => {
    removeInput(editor, input);
  }

  return editor;
}

const removeInput = (editor: Editor, input: PromptTemplateInput) => {
  Transforms.removeNodes(editor, {
    voids: true,
    match: (node) => {
      const inputNode = node as SlateInputElement;
      if (inputNode.type && inputNode.type === 'input') {
        return input.key === inputNode.input.key;
      }
      return false;
    }
  })
}