import { colors } from "@src/config/styles/variables";
import { UserContext } from "@src/context/user";
import { Room, RoomQueueSnapshot, RoomQueueSnapshotMatch } from "@src/types/hive/room.types";
import { sendRequest } from "@src/utils/request/request.utils";
import { Collapse, List, Tag } from "antd";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

const AnalyzePageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
`

export const AnalyzePage = () => {

  const [user] = useContext(UserContext);

  const [rooms, setRooms] = useState<Array<Room>>();

  useEffect(() => {
    if (user && !rooms) {
      getRooms();
    }
  }, [user])

  const getRooms = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/room/list',
      body: {
        accountId: user?.accountId
      }
    })
    console.log(result)
    setRooms(result);
  }

  return (
    <AnalyzePageContainer>
      {
        !rooms &&
          <div>
            Loading Rooms...
          </div>
      }
      {
        rooms && rooms.map((room: Room) => {
          return (
            <AnalyzeRoom
              room={room}
            />
          )
        })
      }
    </AnalyzePageContainer>
  )

}

interface AnalyzeRoomProps {
  room: Room;
}

const AnalyzeRoomContainer = styled.div`
  width: 800px;
  max-width: 100%;
  margin-bottom: 16px;

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }

  .room-queue-data-group {
    margin-bottom: 32px;

    .room-queue-data-item {
      margin-bottom: 4px;

      .room-queue-data-title {
        font-weight: 600;
        margin-right: 4px;
      }
    }
  }
`

const AnalyzeRoomHeader = ({room}: {room: Room}) => {

  const createdAt = DateTime.fromISO(room.createdAt).toFormat('M/dd hh:mma');

  return (
    <div>
      <b>{room.topic}</b> &middot; {room.status} &middot; {createdAt}
    </div>
  )
}

const AnalyzeRoom = ({room}: AnalyzeRoomProps) => {

  const [roomQueueSnapshot, setRoomQueueSnapshot] = useState<RoomQueueSnapshot>();
  const [hasLoaded, setHasLoaded] = useState(false);

  const getSnapshot = async () => {
    if (hasLoaded) {
      return;
    }
    const result = await sendRequest({
      method: 'get',
      url: '/room/snapshot',
      body: {
        roomId: room.id
      }
    })
    console.log(result);
    setHasLoaded(true);
    setRoomQueueSnapshot(result);
  }

  const onChange = (activeRoomIds: any) => {
    if (!activeRoomIds?.length) {
      return;
    }
    getSnapshot();
  }

  return (
    <AnalyzeRoomContainer>
      <Collapse
        onChange={onChange}
      >
        <Collapse.Panel
          key={room.id}
          header={<AnalyzeRoomHeader room={room} />}
        >
          {
            !hasLoaded &&
              <div>
                Loading room matching data
              </div>
          }
          {
            hasLoaded && !roomQueueSnapshot &&
              <div>
                No matching data for this room
              </div>
          }
          {
            roomQueueSnapshot &&
              <div className="room-queue-data">
                <RoomQueueSnapshotRoomData
                  roomQueueSnapshot={roomQueueSnapshot}
                />
                <RoomQueueSnapshotMatches
                  roomQueueSnapshot={roomQueueSnapshot}
                  isMatched={true}
                />
                <RoomQueueSnapshotMatches
                  roomQueueSnapshot={roomQueueSnapshot}
                  isMatched={false}
                />
              </div>
          }
        </Collapse.Panel>
      </Collapse>
    </AnalyzeRoomContainer>
  )

}

interface RoomQueueSnapshotRoomDataProps {
  roomQueueSnapshot: RoomQueueSnapshot
}

const RoomQueueSnapshotRoomData = ({roomQueueSnapshot}: RoomQueueSnapshotRoomDataProps) => {

  const sortTopicSummariesByWeight = (summaryA: any, summaryB: any) => {
    return summaryB.weight - summaryA.weight;
  }

  return (
    <div className="room-queue-data-group">
      <h3>
        Request Info
      </h3>
      <div className="room-queue-data-item">
        <span className="room-queue-data-title">
          User Request:
        </span>
        <span>
          {roomQueueSnapshot.roomData.query}
        </span>
      </div>
      <div className="room-queue-data-item">
        <span className="room-queue-data-title">
          AI Generated Topic:
        </span>
        <span>
          {roomQueueSnapshot.roomData.topic}
        </span>
      </div>
      <div className="room-queue-data-item">
        <span className="room-queue-data-title">
          Matching Components:
        </span>
        <span>
          {
            roomQueueSnapshot.roomData.topicSummaries.sort(
              sortTopicSummariesByWeight
            ).map((topicSummary, index) => {
              return (
                <span>
                  {
                    index !== 0 &&
                      <span>
                        &nbsp;&middot;&nbsp;
                      </span>
                  }
                  {topicSummary.text} ({topicSummary.weight})
                </span>
              )
            })
          }
        </span>
      </div>
    </div>
  )

}

interface RoomQueueSnapshotMatchesProps {
  roomQueueSnapshot: RoomQueueSnapshot,
  isMatched: boolean
}

const RoomQueueSnapshotMatches = ({roomQueueSnapshot, isMatched}: RoomQueueSnapshotMatchesProps) => {

  let title = 'Matched Users';
  if (!isMatched) {
    title = 'Rejected Users';
  }

  const matches = roomQueueSnapshot.matches.filter((match) => {
    return match.isMatched === isMatched;
  })

  return (
    <div className="room-queue-data-group">
      <h3>
        {title}
      </h3>
      {
        matches.map((match) => {
          return (
            <RoomQueueSnapshotMatchItem match={match} />
          )
        })
      }
    </div>
  )

}

interface RoomQueueSnapshotMatchProps {
  match: RoomQueueSnapshotMatch
}

const RoomQueueSnapshotMatchItem = ({match}: RoomQueueSnapshotMatchProps) => {

  const matchDisplayItems = [
    {
      label: 'Profile Summaries',
      dataKey: 'profileSummaries'
    },
    {
      label: 'Dislikes',
      dataKey: 'dislikes'
    },
    {
      label: 'AI Match Reason',
      dataKey: 'reason'
    }
  ]

  const renderMatchDisplayItem = (data: Array<string>) => {

    return (
      <span>
        {
          data.map((text) => {
            return (
              <Tag>
                {text}
              </Tag>
            )
          })
        }
      </span>
    )

  }

  return (
    <div
      style={{
        marginBottom: 8
      }}
    >
      <List
        header={
          <div>
            <div
              style={{
                color: colors.darkGray
              }}
            >
              User ID: {match.userId}
            </div>
          </div>
        }
        bordered
        dataSource={matchDisplayItems}
        renderItem={(item) => {
          const matchData: any = match;
          if (item.dataKey === 'reason') {
            return (
              <List.Item>
                {item.label}:&nbsp;
                <span
                  style={{
                    fontWeight: 600,
                    color: colors.blue
                  }}
                >
                  {match.reason}
                </span>
              </List.Item>
            )
          }
          return (
            <List.Item>
              {item.label}: {renderMatchDisplayItem(matchData[item.dataKey])}
            </List.Item>
          )
        }}
      />
    </div>
  )

}
