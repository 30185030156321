import { getApp, initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, signInWithEmailAndPassword, User, signOut, connectAuthEmulator } from "firebase/auth";
import { useEffect, useState } from "react";

const initialize = () => {
  const params = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  };
  const app = initializeApp(params);
  const auth = getAuth(app);
  if (process.env.REACT_APP_AUTH_EMULATOR_HOST) {
    connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR_HOST)
  }
  auth.setPersistence(browserLocalPersistence);
}

try {
  getApp();
} catch {
  initialize();
}

export const getCurrentUser = () => {
  const app = getApp();
  const auth = getAuth(app);
  return auth.currentUser;
}

export const getCurrentUserToken = async () => {
  const user = getCurrentUser();
  if (user) {
    const token = await user.getIdToken();
    return token;
  };
  return;
}

export const signInWithEmail = async (email: string, password: string) => {
  const app = getApp();
  const auth = getAuth(app);
  const result = await signInWithEmailAndPassword(auth, email, password);
  return result.user;
}

export const logout = async () => {
  const app = getApp();
  const auth = getAuth(app);
  await signOut(auth);
}

export const getFirebaseAuth = () => {
  const app = getApp();
  const auth = getAuth(app);
  return auth;
}

export const useFirebaseAuthentication = () => {
  const [authUser, setAuthUser]: [User | undefined, (value?: User) => void] = useState();

  useEffect(() =>{
    const app = getApp();
    const auth = getAuth(app);
    const unlisten = auth.onAuthStateChanged(
        (authUser) => {
          authUser
            ? setAuthUser(authUser)
            : setAuthUser();
        },
     );
     return () => {
        unlisten();
     }
  }, []);

  return authUser;
}