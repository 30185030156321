import { useEffect, useState } from "react";
import styled from "styled-components";
import { ChatDataRequest, ChatQueryDataResponseAnswer, ChatQueryResponseTopUser } from "./log.types";
import { sendRequest } from "@src/utils/request/request.utils";
import { Account } from "@src/types/account.types";
import { colors } from "@src/config/styles/variables";
import { Collapse, CollapseProps, Divider, Tag } from "antd";
import { DateTime } from "luxon";

const LogPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
`

interface AccountMap {
  [accountId: string]: Account
}

export const LogPage = () => {

  const [accountMap, setAccountMap] = useState<AccountMap>();
  const [selectedAccountId, setSelectedAccountId] = useState<string>();

  const [dataRequests, setDataRequests] = useState<Array<ChatDataRequest>>();

  useEffect(() => {
    if (!dataRequests) {
      fetchDataRequests();
    }
    if (!accountMap) {
      fetchAccounts();
    }
  }, [])

  const fetchAccounts = async () => {
    const response = await sendRequest({
      method: 'get',
      url: '/account/list'
    })
    const accounts: AccountMap = {};
    response.forEach((account: Account) => {
      accounts[account.id] = account;
    })
    setAccountMap(accounts);
  }

  const fetchDataRequests = async () => {
    let params: any = {};
    if (selectedAccountId) {
      params.accountId = selectedAccountId;
    }
    const result = await sendRequest({
      method: 'get',
      url: '/chat/logs',
      body: params
    })
    setDataRequests(result);
  }

  return (
    <LogPageContainer>
      {
        accountMap && dataRequests && dataRequests.map((dataRequest) => {
          return (
            <div key={dataRequest.id}>
              <DataRequestLog
                dataRequest={dataRequest}
                accountMap={accountMap}
              />
            </div>
          )
        })
      }
    </LogPageContainer>
  )

}

const DataRequestLogContainer = styled.div`
  width: 800px;
  max-width: 100%;
  background-color: ${colors.white};
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 12px;

  .data-request-heading {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-bottom: 8px;

    .data-request-input {
      font-size: 16px;
      font-weight: bold;
    }
  }
`

interface DataRequestLogProps {
  dataRequest: ChatDataRequest;
  accountMap: AccountMap;
}

const DataRequestLog = ({dataRequest, accountMap}: DataRequestLogProps) => {

  const account = accountMap[dataRequest.accountId];
  const createdAt = DateTime.fromISO(dataRequest.createdAt);

  const panels: CollapseProps['items'] = []

  if (dataRequest.metadata?.data) {
    panels.push({
      key: 'data',
      label: 'Returned Data',
      children: (
        <DataRequestLogData
          dataRequest={dataRequest}
        />
      )
    })
  }

  if (dataRequest.metadata?.people) {
    panels.push({
      key: 'people',
      label: 'Returned People',
      children: (
        <DataRequestLogPeople
          dataRequest={dataRequest}
        />
      )
    })
  }

  return (
    <DataRequestLogContainer>
      <div
        className="data-request-heading"
      >
        <Tag>{account.name}</Tag>
        <div className="data-request-input">
          {dataRequest.input}
        </div>
      </div>
      <div className="data-request-date">
        {createdAt.toFormat('M/d/yy hh:mm a')}
      </div>
      <Divider
        style={{
          marginTop: '8px',
          marginBottom: '8px'
        }}
      />
      <div>
        <Collapse
          items={panels}
        />
      </div>
    </DataRequestLogContainer>
  )

}

const DataRequestLogDataContainer = styled.div`
  
`

interface DataRequestLogDataProps {
  dataRequest: ChatDataRequest;
}

const DataRequestLogData = ({dataRequest}: DataRequestLogDataProps) => {

  const data = dataRequest.metadata?.data;
  if (!data) {
    return (
      <div>
        No answers found. Probably an error.
      </div>
    )
  }

  return (
    <DataRequestLogDataContainer>
      {
        !data?.answers?.length &&
          <div>
            No Answers Found
          </div>
      }
      {
        data.answers?.length &&
          data.answers.map((answer, index) => {
            return (
              <DataRequestLogDataAnswer
                key={index}
                answer={answer}
              />
            )
          })
      }
    </DataRequestLogDataContainer>
  )

}

const DataRequestLogDataAnswerContainer = styled.div`
  
`

interface DataRequestLogDataAnswerProps {
  answer: ChatQueryDataResponseAnswer;
}

const DataRequestLogDataAnswer = ({answer}: DataRequestLogDataAnswerProps) => {

  return (
    <DataRequestLogDataAnswerContainer>
      <div>
        <b>Answer: </b>{answer.answer}
      </div>
      <br />
      {
        answer.snippet &&
          <div>
            <b>Snippet: </b>{answer.snippet}
          </div>
      }
      <Divider
        style={{
          marginTop: '8px',
          marginBottom: '8px'
        }}
      />
      <div>
        <b>Document</b>
      </div>
      <div>
        <div>
          Title: {answer.document.title}
        </div>
        <div>
          Platform: {answer.document.platform}
        </div>
      </div>
    </DataRequestLogDataAnswerContainer>
  )

}

const DataRequestLogPeopleContainer = styled.div`
  
`

interface DataRequestLogPeopleProps {
  dataRequest: ChatDataRequest;
}

const DataRequestLogPeople = ({dataRequest}: DataRequestLogPeopleProps) => {

  const people = dataRequest.metadata?.people;

  if (!people) {
    return (
      <div>
        No people data found. Probably an error.
      </div>
    )
  }

  const {topUsers, relevantPeopleCount, summary} = people;

  return (
    <DataRequestLogPeopleContainer>
      <div>
        <b>Summary :</b> {summary}
      </div>
      <br />
      <div>
        <b>Total People :</b> {relevantPeopleCount}
      </div>
      <br />
      <div>
        <b>Top Users</b>
      </div>
      <br />
      {
        topUsers && topUsers.map((topUser) => {
          return (
            <div
              key={topUser.user.id}
            >
              <DataRequestLogPeopleItem
                topUser={topUser}
              />
              <Divider
                style={{
                  marginTop: '6px',
                  marginBottom: '6px'
                }}
              />
            </div>
          )
        })
      }
    </DataRequestLogPeopleContainer>
  )

}

const DataRequestLogPeopleItemContainer = styled.div`
  
`

interface DataRequestLogPeopleItemProps {
  topUser: ChatQueryResponseTopUser;
}

const DataRequestLogPeopleItem = ({topUser}: DataRequestLogPeopleItemProps) => {

  return (
    <div
      style={{
        marginBottom: '4px'
      }}
    >
      <div>
        Name: {topUser.user.firstName} {topUser.user.lastName}
      </div>
      <div>
        Reason: {topUser.reason}
      </div>
    </div>
  )

}
