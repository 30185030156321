import { colors, fonts } from "@src/config/styles/variables";
import { Card, CardProps } from "antd";
import styled from "styled-components/macro";

export const StandardCard = styled(Card)<CardProps>`
  border: 1px solid ${colors.mediumGray};
  border-radius: 10px;

  .ant-card-head-title {
    font-family: ${fonts.secondary};
    font-weight: 700;
  }

  .ant-card-body {
    border-radius: 0 0 10px 10px;
  }
`