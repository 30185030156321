import { colors } from "@src/config/styles/variables";
import { Layout } from "antd";
import { Outlet } from "react-router";
import styled from "styled-components/macro";
import { LayoutSider, SiderMenu } from "./sider";

const MainLayout = styled(Layout)`
  min-height: 100vh;
  background-color: ${colors.lightGray};

  .ant-layout-sider {
    height: 100vh;
    position: fixed;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .ant-layout-content {
    padding-left: 200px;
  }
`

const MainContentLayout = styled(Layout)`
  min-height: 100vh;
  align-items: center;
`

const MainContent = styled(Layout.Content)`
  width: 100%;
`

export const MainPageContainer = () => {

  return (
    <MainLayout>
      <LayoutSider
        collapsible
        collapsed={false}
      >
        <SiderMenu />
      </LayoutSider>
      <MainContentLayout>
        <MainContent>
          <Outlet />
        </MainContent>
      </MainContentLayout>
    </MainLayout>
  )

}
