export enum PromptInputOptionType {
  STRING = 'string',
  ACCOUNT_ID = 'accountId'
}

export enum PromptIndexObjectType {
  KNOWLEDGE_DOCUMENT = 'knowledgeDocument'
}

export interface PromptIndexObjectOption {
  label: string;
  type: PromptInputOptionType;
}

export interface PromptIndexObjectMap {
  [key: string]: PromptIndexObjectOption
}

export interface PromptTemplateMap {
  [templateKey: string]: PromptTemplateItem;
}

export type RunPromptFunction = (
  template: string,
  objectId?: string,
  options?: any
) => Promise<any>;

export interface PromptFilter {
  label: string;
  tooltip?: string;
}

export interface PromptFilterMap {
  [filterKey: string]: PromptFilter;
}

export interface PromptTemplateItem extends PromptTemplate {
  id: string;
}

export interface PromptTemplate {
  label: string;
  template: string;
  inputs: PromptTemplateInputMap;
  options: PromptIndexObjectMap;
  objectType: PromptIndexObjectType;
  getIndexObjects: (filters: PromptIndexObjectMap) => Promise<Array<any>>;
  runPrompt: RunPromptFunction;
}

export interface RunPromptTemplateBody {
  key: string;
  template?: string;
  objectId?: string;
  options?: any;
}

export interface PromptTemplateInputMap {
  [key: string]: {
    label: string;
    tooltip: string;
  };
}

export interface PromptTemplateInput {
  key: string;
  label: string;
}

export interface PromptTemplateFilter {
  key: string;
  label: string;
}
