import { Form, FormProps } from "antd";
import styled from "styled-components";

import { fonts } from "@src/config/styles/variables";

export const StandardForm = styled(Form)<FormProps>`
  
  .ant-form-item-label {
    
    label {
      font-family: ${fonts.tertiary};
    }
  }
`