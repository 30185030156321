import { AccountSelectItem, AccountSelector } from "@src/components/admin/account-selector";
import { StandardButton } from "@src/components/standard/button/button";
import { StandardCard } from "@src/components/standard/card/card";
import { StandardInput } from "@src/components/standard/form/input";
import { colors } from "@src/config/styles/variables";
import { sendRequest } from "@src/utils/request/request.utils";
import type { CollapseProps } from 'antd';
import { Collapse, Divider, Typography, message } from "antd";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import mrkdwn from "slack-markdown";
import styled from "styled-components";
import { ChatDataRequestUsers, ChatQueryResponse, DebugQueryNode, DebugQueryResultDocument, DebugQueryResults, KNOWLEDGE_PRIORITY_LEVEL } from "./debug.types";

const DebugPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;

  .debug-query-inputs {
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .account-select, .debug-query-input {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .select-account-actions {
        display: none;
      }
    }

    .debug-query-submit {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .ant-btn {
        min-width: 200px;
      }
    }
  }
`

export const DebugPage = () => {

  const [accountId, setAccountId] = useState<string>();
  const [query, setQuery] = useState('');

  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [results, setResults] = useState<DebugQueryResults>();

  useEffect(() => {
    if (accountId && query?.trim().length) {
      setIsReady(true);
    }
  }, [accountId, query])

  const updateAccount = (account: AccountSelectItem) => {
    if (account) {
      setAccountId(account.value);
      return;
    }
    setAccountId(undefined);
  }

  const updateQuery = (event: any) => {
    setQuery(event.target.value);
  }

  const handleQuerySubmit = async (event: any) => {
    if (event.key === 'Enter') {
      await testQuery();
    }
  };

  const testQuery = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await sendRequest({
        method: 'post',
        url: '/knowledge/debug/query',
        body: {
          accountId: accountId,
          query: query
        }
      })
      setResults(result);
    } catch (err: any) {
      message.error(err.message);
    }
    setIsLoading(false);
  }

  return (
    <DebugPageContainer>
      <StandardCard>
        <div className="debug-query-inputs">
          <div
            className="account-select"
          >
            <div>
              Account
            </div>
            <AccountSelector
              onSelect={updateAccount}
              skipConfirm
            />
          </div>
          <div
            className="debug-query-input"
          >
            <div>
              Query
            </div>
            <StandardInput
              onChange={updateQuery}
              onKeyDown={handleQuerySubmit}
              placeholder="What is...?"
            />
          </div>
          {
            isReady &&
              <div
                className="debug-query-submit"
              >
                <StandardButton
                  onClick={testQuery}
                  type="primary"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Run Query
                </StandardButton>
              </div>
          }
        </div>
      </StandardCard>
      <br /><br />
      {
        results &&
          <StandardCard
            title="Query Results"
          >
            <DebugQueryResultView
              results={results}
            />
          </StandardCard>
      }
    </DebugPageContainer>
  )

}

interface DebugQueryResultViewProps {
  results: DebugQueryResults;
}

const DebugQueryResultViewContainer = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const DebugQueryResultView = ({results}: DebugQueryResultViewProps) => {

  const resultPanels: CollapseProps['items'] = [
    {
      key: 'relevantDocuments',
      label: 'Source Documents',
      children: <DebugQueryResultDocuments documents={results.relevantDocuments} />,
    },
    {
      key: 'relevantNodes',
      label: 'Nodes from Vector Search',
      children: <DebugQueryResultVectors
        documents={results.relevantDocuments}
        nodes={results.relevantNodes}
      />,
    },
    {
      key: 'rankedNodes',
      label: 'Reranked Nodes',
      children: <DebugQueryResultVectors
        documents={results.relevantDocuments}
        nodes={results.rankedNodes}
      />,
    },
    {
      key: 'nextNodes',
      label: 'Additional (Next) Nodes',
      children: <DebugQueryResultVectors
        documents={results.relevantDocuments}
        nodes={results.nextNodes}
      />,
    },
    {
      key: 'peopleData',
      label: 'People',
      children: <DebugQueryResultPeople
        peopleResult={results.peopleResult}
        documentPeopleResult={results.documentPeopleResult}
        relevantDocuments={results.relevantDocuments}
      />,
    },
    {
      key: 'prompt',
      label: 'Prompt',
      children: <DebugQueryResultPrompt
        prompt={results.prompt}
      />,
    }
  ];

  return (
    <DebugQueryResultViewContainer>
      <div>
        {
          results.answer &&
            <div>
              <Typography.Title
                level={4}
                style={{
                  marginTop: '4px'
                }}
                
              >
                Answer
              </Typography.Title>
              <DebugQueryResultAnswer
                answer={results.answer}
              />
            </div>
        }
      </div>
      <div>
        <Collapse
          items={resultPanels}
        />
      </div>
    </DebugQueryResultViewContainer>
  )

}

interface DebugQueryResultAnswerProps {
  answer: string;
}

const DebugQueryResultAnswerContainer = styled.div`
  white-space: pre-wrap;

  .debug-query-answer {
    display: flex;
    flex-direction: column;

    .debug-query-answer-document {
      color: ${colors.darkGray};
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid ${colors.mediumGray};
    }
  }
`

const DebugQueryResultAnswer = ({answer}: DebugQueryResultAnswerProps) => {

  const answerHtml = mrkdwn.toHTML(answer);

  return (
    <DebugQueryResultAnswerContainer>
      <div
        className="debug-query-answer"
      >
        <div dangerouslySetInnerHTML={{__html: answerHtml}} />
      </div>
    </DebugQueryResultAnswerContainer>
  )

}

interface DebugQueryResultPromptProps {
  prompt: string;
}

const DebugQueryResultPromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: pre-wrap;
  
  .section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }
`

const DebugQueryResultPrompt = ({prompt}: DebugQueryResultPromptProps) => {

  return (
    <DebugQueryResultPromptContainer>
      <div
        className="prompt-info"
      >
        <div className="section-title">
          Prompt
        </div>
        {prompt}
      </div>
    </DebugQueryResultPromptContainer>
  )
}

interface DebugQueryResultVectorsProps {
  documents: Array<DebugQueryResultDocument>;
  nodes: Array<DebugQueryNode>;
}

const DebugQueryResultVectorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

interface QueryResultDocumentMap {
  [documentId: string]: DebugQueryResultDocument
}

const generateDocumentMap = (documents: Array<DebugQueryResultDocument>) => {
  const documentMap: QueryResultDocumentMap = {};
  documents.forEach((document) => {
    if (!document?.id) {
      return;
    }
    documentMap[document.id] = document;
  })
  return documentMap;
}

const DebugQueryResultVectors = ({nodes, documents}: DebugQueryResultVectorsProps) => {

  const documentMap = generateDocumentMap(documents);

  return (
    <DebugQueryResultVectorsContainer>
      {
        nodes.map((node, index) => {
          const document = documentMap[node.metadata.knowledgeDocumentId];
          return (
            <div
              key={`${node.metadata.id}-${index}`}
            >
              <DebugQueryResultVector
                node={node}
                document={document}
              />
            </div>
          )
        })
      }
    </DebugQueryResultVectorsContainer>
  )

}

interface DebugQueryResultVectorProps {
  node: DebugQueryNode;
  document?: DebugQueryResultDocument;
}

const DebugQueryResultVectorContainer = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.lightGray};

  .result-node-info {
    white-space: pre-wrap;
  }
`

const DebugQueryResultVector = ({node, document}: DebugQueryResultVectorProps) => {

  let documentEdited: DateTime | undefined;
  if (document?.documentCreatedAt) {
    documentEdited = DateTime.fromISO(document.documentCreatedAt);
  }
  if (document?.documentEditedAt) {
    documentEdited = DateTime.fromISO(document.documentEditedAt);
  }

  return (
    <DebugQueryResultVectorContainer>
      <div
        className="result-node-info"
      >
        <b>Score:</b> {node.score}
      </div>
      <div
        className="result-node-info"
      >
        <b>Document Title:</b> {node.metadata.title}
      </div>
      <div
        className="result-node-info"
      >
        <b>Document URL:</b> {node.metadata.url}
      </div>
      <br />
      <div
        className="result-node-info"
      >
        <b>Text</b><br /><br />{node.metadata.text}
      </div>
    </DebugQueryResultVectorContainer>
  )

}

interface DebugQueryResultDocumentsProps {
  documents: Array<DebugQueryResultDocument>;
}

const DebugQueryResultDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const DebugQueryResultDocuments = ({documents}: DebugQueryResultDocumentsProps) => {

  return (
    <DebugQueryResultDocumentsContainer>
      {
        documents.map((document) => {
          return (
            <div
              key={document.id}
            >
              <DebugQueryResultDocumentItem
                document={document}
              />
            </div>
          )
        })
      }
    </DebugQueryResultDocumentsContainer>
  )

}

interface DebugQueryResultDocumentProps {
  document: DebugQueryResultDocument;
}

const DebugQueryResultDocumentContainer = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 4px;

  .ant-btn {
    width: 250px;
  }
`

const DebugQueryResultDocumentDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const DebugQueryResultDocumentItem = ({document}: DebugQueryResultDocumentProps) => {

  const [isShowingContents, setIsShowingContents] = useState(false);

  let createdAt: DateTime | undefined;
  if (document.documentCreatedAt) {
    createdAt = DateTime.fromISO(document.documentCreatedAt);
  }
  let editedAt: DateTime | undefined;
  if (document.documentEditedAt) {
    editedAt = DateTime.fromISO(document.documentEditedAt);
  }

  return (
    <DebugQueryResultDocumentContainer>
      <div
        className="result-document-info"
      >
        Name: {document.title}
      </div>
      <div
        className="result-document-info"
      >
        Platform: {document.platform}
      </div>
      <div
        className="result-document-info"
      >
        URL: {document.url}
      </div>
      {
        createdAt &&
          <div
            className="result-document-info"
          >
            Created At: {createdAt.toFormat('MM/dd/yyyy')}
          </div>
      }
      {
        editedAt &&
          <div
            className="result-document-info"
          >
            Edited At: {editedAt.toFormat('MM/dd/yyyy')}
          </div>
      }
    </DebugQueryResultDocumentContainer>
  )

}

interface DebugQueryResultPeopleProps {
  peopleResult: ChatQueryResponse;
  documentPeopleResult: ChatDataRequestUsers;
  relevantDocuments: Array<DebugQueryResultDocument>;
}

const DebugQueryResultPeopleContainer = styled.div`
  .document-authors {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .document-author-group {
      font-style: italic;
    }
  }

  .relevant-people {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .relevant-top-user {
      margin-bottom: 12px;

      .relevant-top-user-reason {
        margin-top: 4px;
        font-style: italic;
      }
    }
  }
`

const DebugQueryResultPeople = (props: DebugQueryResultPeopleProps) => {

  const {peopleResult, documentPeopleResult, relevantDocuments} = props;

  const documentMap: Record<string, DebugQueryResultDocument> = {};
  relevantDocuments.forEach((document) => {
    if (!document?.id) {
      return;
    }
    documentMap[document.id] = document;
  })

  return (
    <DebugQueryResultPeopleContainer>
      <div className="document-authors">
        {
          Object.entries(documentPeopleResult).map(([documentId, {editors, creator}]) => {
            const document = documentMap[documentId];
            if (!document) {
              return null;
            }
            return (
              <div key={documentId}>
                <div>
                  <b>{document.title}</b>
                </div>
                {
                  creator &&
                    <div>
                      <div className="document-author-group">Created By</div>
                      {creator.firstName} {creator.lastName} ({creator.email})
                    </div>
                }
                {
                  editors?.length &&
                    <div>
                      <div className="document-author-group">Editors</div>
                      {
                        editors.map((editor) => {
                          return (
                            <div>
                              {editor.firstName} {editor.lastName} ({editor.email})
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </div>
            )
          })
        }
      </div>
      <Divider />
      {
        peopleResult?.topUsers?.length &&
          <div className="relevant-people">
            <div>
              {peopleResult.summary}
            </div>
            <div>
              <b>Relevant People Count:</b> {peopleResult.relevantPeopleCount}
            </div>
            <div>
              {
                peopleResult.topUsers.map((topUser) => {
                  return (
                    <div className="relevant-top-user" key={topUser.user.id}>
                      <div>
                        {topUser.user.firstName} {topUser.user.lastName} ({topUser.user.email})
                      </div>
                      <div className="relevant-top-user-reason">
                        {topUser.reason}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
      }
      {
        !peopleResult?.topUsers?.length &&
          <div>
            No profiles found
          </div>
      }
    </DebugQueryResultPeopleContainer>
  )

}
