import styled from "styled-components"
import { useSpring, animated } from "@react-spring/web"

import beeFlying from "@src/assets/images/bee-flying.png"
import { colors, fonts } from "@src/config/styles/variables"
import { useEffect } from "react"

const HiveChatLoadingContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 120px;
  overflow: hidden;

  .chat-loading-animated-container {
    height: 100px;
    width: 100%;
    border: 1px solid ${colors.lightGray};
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    
    > div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .chat-loading-text-container {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: ${fonts.secondary};
    font-size: 24px;
    font-weight: 600;
  }

  .chat-loading-bee-container {
    display: flex;
    align-items: center;
    overflow-x: hidden;

    > div {
      position: relative;
      height: 75%;
      margin-left: 32px;
      width: 100%;

      img {
        height: 100%;
      }
    }
  }
`

interface HiveChatLoadingProps {
  isComplete: boolean;
  onFinish?: () => void;
}

export const HiveChatLoading = ({isComplete, onFinish}: HiveChatLoadingProps) => {

  const [containerStyles, api] = useSpring(
    () => ({
      from: {
        transform: 'translateY(-100%)',
      },
      to: {
        transform: 'translateY(0%)'
      },
      delay: 500,
      config: {
        clamp: true
      }
    }),
    []
  )

  useEffect(() => {
    if (isComplete) {
      api.start({
        from: {
          transform: 'translateY(0%)',
        },
        to: {
          transform: 'translateY(-100%)',
        },
        config: {
          clamp: true,
          friction: 0
        },
        onRest: () => {
          if (onFinish) {
            onFinish();
          }
        }
      })
    }
  }, [isComplete])

  return (
    <HiveChatLoadingContainer>
      <animated.div
        style={containerStyles}
        className="chat-loading-animated-container"
      >
        <div
          className="chat-loading-text-container"
        >
          Searching...
        </div>
        <div
          className="chat-loading-bee-container"
        >
          <HiveChatLoadingBee />
        </div>
        <div
          className="chat-loading-background-container"
        >

        </div>
      </animated.div>
    </HiveChatLoadingContainer>
  )

}

const HiveChatLoadingBee = () => {

  const [verticalStyles] = useSpring(
    () => ({
      from: {
        y: -3,
        // transform: 'translateX(0%)'
      },
      to: {
        y: 0,
        // transform: 'translateX(100%)'
      },
      config: {
        tension: 10,
        friction: 0
      }
    }),
    []
  )

  const [horizontalStyles] = useSpring(
    () => ({
      from: {
        transform: 'translateX(-10%)'
      },
      to: {
        transform: 'translateX(105%)'
      },
      loop: true,
      config: {
        tension: .02,
        friction: .1,
        clamp: true
      }
    }),
    []
  )

  const beeStyles = {
    ...verticalStyles,
    ...horizontalStyles
  }

  return (
    <animated.div style={beeStyles}>
      <img src={beeFlying} alt="Sam the bee" />
    </animated.div>
  )

}