import { paragon } from '@useparagon/connect';
import { IntegrationPlatformItem, IntegrationPlatformProps } from "../integrations";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { sendRequest } from '@src/utils/request/request.utils';
import { Account } from '@src/types/account.types';

const slackScopes = [
  'chat:write',
  'channels:read',
  'groups:history',
  'groups:write',
  'groups:read',
  'groups:write.invites',
  'groups:write.topic',
  'im:history',
  'im:read',
  'im:write',
  'team:read',
  'users:read',
  'users.profile:read',
  'users:read.email'
]

const slackRedirectUrl = `${process.env.REACT_APP_WEB_URL}/integrations/slack`;

const slackAuthUrl = `https://slack.com/oauth/v2/authorize?`
  + `client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}`
  + `&redirect_uri=${slackRedirectUrl}`
  + `&scope=${slackScopes.join(',')}`
  + `&user_scope=`

interface SlackIntegrationParams {
  teamId: string;
  botToken: string;
  botId?: string;
  botUserId?: string;
}

export const IntegrationSlack = (props: IntegrationPlatformProps) => {

  const [searchParams] = useSearchParams();

  const [tokenCode] = useState(searchParams.get('code'));
  const [isConnectionComplete, setIsConnectionComplete] = useState(false);

  const [account, setAccount] = useState<Account>();

  const connectToSlack = async () => {
    window.location.href = slackAuthUrl;
  }

  useEffect(() => {
    if (tokenCode) {
      sendTokenExchange();
    }
    fetchAccount();
  }, [])

  const fetchAccount = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/account'
    })
    setAccount(result);
  }

  const sendTokenExchange = async () => {

    if (!tokenCode || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_SECRET) {
      return;
    }

    const data = new URLSearchParams();
    data.append('client_id', process.env.REACT_APP_SLACK_CLIENT_ID);
    data.append('client_secret', process.env.REACT_APP_SLACK_CLIENT_SECRET);
    data.append('code', tokenCode);
    data.append('redirect_uri', slackRedirectUrl);

    const result = await fetch('https://slack.com/api/oauth.v2.access', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString()
    });
    const response = await result.json();
    
    if (!response.ok) {
      message.error(`Slack integration failed: ${response.error}`);
      return;
    }

    const params: SlackIntegrationParams = {
      teamId: response.team.id,
      botToken: response.access_token,
      botUserId: response.bot_user_id
    }

    const updatedAccount = await setAccountIntegration(params);

    setAccount(updatedAccount);

    setIsConnectionComplete(true);

    message.success('Connected to Slack!');

  }

  const setAccountIntegration = async (params: SlackIntegrationParams) => {
    const result = await sendRequest({
      method: 'post',
      url: '/account/integrations/slack',
      body: params
    })

    return result;
  }

  return (
    <IntegrationPlatformItem
      name="slack"
      label="Slack"
      isEnabled={account?.integrations?.slack ? true : false}
      connect={connectToSlack}
    />
  )
}