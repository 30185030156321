import { useState } from "react";
import styled from "styled-components";
import { PromptIndexObjectOption, PromptIndexObjectType, PromptInputOptionType, PromptTemplate, PromptTemplateItem } from "./prompts.types";
import { AccountSelectItem, AccountSelector } from "@src/components/admin/account-selector";
import { StandardInput } from "@src/components/standard/form/input";
import { StandardButton } from "@src/components/standard/button/button";
import { sendRequest } from "@src/utils/request/request.utils";
import { Divider, List } from "antd";
import JSONPretty from "react-json-pretty";

import 'react-json-pretty/themes/monikai.css';

const PromptTemplateRunnerViewContainer = styled.div`
  
`

interface PromptTemplateRunnerViewProps {
  template: PromptTemplateItem;
  updatedTemplate: string;
}

export const PromptTemplateRunnerView = ({template, updatedTemplate}: PromptTemplateRunnerViewProps) => {

  const [options, setOptions] = useState<any>();
  const [inputObjects, setInputObjects] = useState<Array<any>>();

  const [isRunningPrompt, setIsRunningPrompt] = useState<boolean>(false);
  const [promptResults, setPromptResults] = useState<any>();

  const handleOptionChange = (optionKey: string, value: any) => {
    let updatedOptions: any = {};
    if (options) {
      updatedOptions = {...options};
    }
    updatedOptions[optionKey] = value;
    setOptions(updatedOptions);
  }

  const searchInputObjects = async () => {
    const result = await sendRequest({
      method: 'post',
      url: '/prompt/templates/inputs',
      body: {
        key: template.id,
        options: {...options}
      }
    })
    setInputObjects(result);
  }

  const runPromptOnInput = async (input: any) => {
    if (isRunningPrompt) {
      return;
    }
    setIsRunningPrompt(true);
    try {
      const objectId = input.id;
      const result = await sendRequest({
        method: 'post',
        url: '/prompt/templates/run',
        body: {
          template: updatedTemplate,
          key: template.id,
          objectId: objectId,
          options: {...options}
        }
      })
      setPromptResults(result)
    } catch (err: any) {
      console.log(err);
    }
    setIsRunningPrompt(false);
  }

  return (
    <PromptTemplateRunnerViewContainer>
      <div>
        <PromptTemplateRunnerOptions
          template={template}
          onChangeOption={handleOptionChange}
        />
      </div>
      <div>
        <StandardButton
          onClick={searchInputObjects}
        >
          Search Input Objects
        </StandardButton>
      </div>
      {
        inputObjects && !isRunningPrompt && !promptResults &&
          <>
            <Divider />
            <PromptTemplateRunnerInputs
              template={template}
              inputs={inputObjects}
              runTemplatePrompt={runPromptOnInput}
              isRunningPrompt={isRunningPrompt}
            />
          </>
      }
      {
        (isRunningPrompt || promptResults) &&
          <>
            <Divider />
            <PromptTemplateRunnerResults
              isRunningPrompt={isRunningPrompt}
              results={promptResults}
            />
          </>
      }
    </PromptTemplateRunnerViewContainer>
  )

}

const PromptTemplateRunnerOptionsContainer = styled.div`
  
`

interface PromptTemplateRunnerOptionsProps {
  template: PromptTemplate;
  onChangeOption: (key: string, value: any) => void;
}

export const PromptTemplateRunnerOptions = ({template, onChangeOption}: PromptTemplateRunnerOptionsProps) => {

  const optionsList = Object.entries(template.options);

  return (
    <PromptTemplateRunnerOptionsContainer>
      <div>
        {
          optionsList.map(([optionKey, option]) => {
            return (
              <PromptTemplateRunnerOption
                key={optionKey}
                optionKey={optionKey}
                option={option}
                onChange={onChangeOption}
              />
            )
          })
        }
      </div>
    </PromptTemplateRunnerOptionsContainer>
  )

}

const PromptTemplateRunnerOptionContainer = styled.div`
  margin-bottom: 8px;
  .runner-option-label {
    font-weight: bold;
    margin-bottom: 4px;
  }
`

interface PromptTemplateRunnerOptionProps {
  optionKey: string;
  option: PromptIndexObjectOption;
  onChange: (optionKey: string, value: any) => void;
}

export const PromptTemplateRunnerOption = ({optionKey, option, onChange}: PromptTemplateRunnerOptionProps) => {

  return (
    <PromptTemplateRunnerOptionContainer>
      <div className="runner-option-label">
        {option.label}
      </div>
      {(() => {
        switch (option.type) {
          case PromptInputOptionType.ACCOUNT_ID:
            return <PromptTemplateRunnerOptionAccount
              optionKey={optionKey}
              option={option}
              onChange={onChange}
            />;
          case PromptInputOptionType.STRING:
            return <PromptTemplateRunnerOptionString
              optionKey={optionKey}
              option={option}
              onChange={onChange}
            />;
        }
      })()}
    </PromptTemplateRunnerOptionContainer>
  )

}

interface PromptTemplateRunnerOptionAccountProps {
  optionKey: string;
  option: PromptIndexObjectOption;
  onChange: (optionKey: string, value: any) => void;
}

const PromptTemplateRunnerOptionAccount = (
  {optionKey, option, onChange}: PromptTemplateRunnerOptionAccountProps
) => {

  const onSelectAccount = ({value: accountId}: AccountSelectItem) => {
    onChange(optionKey, accountId);
  }

  return (
    <div>
      <AccountSelector
        onSelect={onSelectAccount}
        skipConfirm
      />
    </div>
  )

}

interface PromptTemplateRunnerOptionStringProps {
  optionKey: string;
  option: PromptIndexObjectOption;
  onChange: (optionKey: string, value: any) => void;
}

const PromptTemplateRunnerOptionString = (
  {optionKey, option, onChange}: PromptTemplateRunnerOptionStringProps
) => {

  const handleChange = (event: any) => {
    onChange(optionKey, event.target.value);
  }

  return (
    <div>
      <StandardInput
        size="small"
        onChange={handleChange}
      />
    </div>
  )

}

interface PromptTemplateRunnerInputsProps {
  template: PromptTemplateItem;
  inputs: Array<any>;
  runTemplatePrompt: (input: any) => void;
  isRunningPrompt: boolean;
}

const PromptTemplateRunnerInputs = (
  {template, inputs, runTemplatePrompt, isRunningPrompt}: PromptTemplateRunnerInputsProps
) => {

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={inputs}
        renderItem={(input: any) => (
          <PromptTemplateRunnerInput
            template={template}
            input={input}
            runTemplatePrompt={runTemplatePrompt}
            isRunningPrompt={isRunningPrompt}
          />
        )}
      />
    </div>
  )

}

interface PromptTemplateRunnerInputProps {
  template: PromptTemplateItem;
  input: any;
  runTemplatePrompt: (input: any) => void;
  isRunningPrompt: boolean;
}

const PromptTemplateRunnerInput = (
  {template, input, runTemplatePrompt, isRunningPrompt}: PromptTemplateRunnerInputProps
) => {

  const inputType = template.objectType;

  return (
    <List.Item
      actions={[
        <StandardButton
          onClick={() => runTemplatePrompt(input)}
          disabled={isRunningPrompt}
        >
          Run Prompt Template
        </StandardButton>
      ]}
    >
      {
        inputType === PromptIndexObjectType.KNOWLEDGE_DOCUMENT &&
          <List.Item.Meta
            title={input.title}
            description={input.platform}
          />
      }
    </List.Item>
  )

}

interface PromptTemplateRunnerResultsProps {
  isRunningPrompt: boolean;
  results?: any;
}

const PromptTemplateRunnerResults = (
  {isRunningPrompt, results}: PromptTemplateRunnerResultsProps
) => {

  return (
    <div>
      {
        isRunningPrompt && !results &&
          <div>
            Running prompt with template...
          </div>
      }
      {
        results &&
          <div>
            <div
              style={{
                fontWeight: 'bold',
                marginBottom: '4px'
              }}
            >
              Results
            </div>
            <JSONPretty
              id="results-json"
              data={results}
            />
          </div>
      }
    </div>
  )

}