import { StandardButton } from "@src/components/standard/button/button"
import { StandardCard } from "@src/components/standard/card/card"
import { sendRequest } from "@src/utils/request/request.utils"
import { Collapse, CollapseProps, Tabs, TabsProps } from "antd"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { PromptFilterMap, PromptTemplate, PromptTemplateItem, PromptTemplateMap } from "./prompts.types"
import { PromptTemplateDiffView } from "./template-diff"
import { PromptTemplateEditor } from "./template-editor"
import { PromptTemplateRunnerView } from "./template-runner"

const PromptEditorPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`

export const PromptEditorPage = () => {

  const [templates, setTemplates] = useState<PromptTemplateMap>();
  const [template, setTemplate] = useState<PromptTemplateItem>();

  const [isEditingTemplate, setIsEditingTemplate] = useState<boolean>(true);
  const [updatedTemplate, setUpdatedTemplate] = useState<string>();
  
  const [filters, setFilters] = useState<PromptFilterMap>();

  useEffect(() => {
    if (!templates) {
      fetchTemplates();
    }
    if (!filters) {
      fetchFilters();
    }
  }, [])

  const fetchTemplates = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/prompt/templates'
    });
    const templateItemsMap: any = {}
    Object.entries(result).forEach(([templateKey, templateItem]: any) => {
      templateItemsMap[templateKey] = {
        id: templateKey,
        ...templateItem
      }
    })
    setTemplates(templateItemsMap);
  }
  
  const fetchFilters = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/prompt/templates/filters'
    });
    setFilters(result);
  }

  const selectTemplate = (template: PromptTemplateItem) => {
    setTemplate(template);
    setUpdatedTemplate(template.template);
  }

  const handleTemplateUpdate = (templateString: string) => {
    setUpdatedTemplate(templateString);
  }

  const promptEditorTabs: TabsProps['items'] = [
    {
      key: 'templateEditor',
      label: 'Edit Prompt Template',
      children: <PromptTemplateEditor
        template={template!}
        filters={filters!}
        setIsEditingTemplate={setIsEditingTemplate}
        onUpdate={handleTemplateUpdate}
      />,
    },
    {
      key: 'templateDiff',
      label: 'View Diff',
      disabled: isEditingTemplate,
      children: <PromptTemplateDiffView
        template={template!}
        updatedTemplate={updatedTemplate}
      />,
    },
    {
      key: 'templateRunner',
      label: 'Run Prompt',
      disabled: isEditingTemplate,
      children: <PromptTemplateRunnerView
        template={template!}
        updatedTemplate={updatedTemplate!}
      />,
    }
  ];

  return (
    <PromptEditorPageContainer>
      {
        !template &&
          <StandardCard
            title={'Pick a Prompt'}
            style={{
              width: '100%'
            }}
          >
            {
              templates &&
                <PromptEditorTemplateList
                  templates={templates}
                  onSelect={selectTemplate}
                />
            }
          </StandardCard>
      }
      {
        template && filters &&
          <StandardCard
            title={template.label}
            style={{
              width: '100%'
            }}
          >
            <Tabs
              items={promptEditorTabs}
              defaultActiveKey={'templateEditor'}
            />
          </StandardCard>
      }
    </PromptEditorPageContainer>
  )

}

interface PromptEditorTemplateListProps {
  templates: PromptTemplateMap;
  onSelect: (template: PromptTemplateItem) => void;
}

const PromptEditorTemplateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const PromptEditorTemplateList = (
  {templates, onSelect}: PromptEditorTemplateListProps
) => {

  return (
    <PromptEditorTemplateListContainer>
      {
        Object.entries(templates).map(([key, template]) => {
          return (
            <div
              key={key}
            >
              <StandardButton
                onClick={() => onSelect(template)}
              >
                {template.label}
              </StandardButton>
            </div>
          )
        })
      }
    </PromptEditorTemplateListContainer>
  )

}
