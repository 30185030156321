import { useSpring, animated } from "@react-spring/web";
import { colors, fonts } from "@src/config/styles/variables";
import { HiveChatConnection, HiveConnectionsMessage, HiveMessage, HiveTextMessage } from "@src/types/hive/chat.types";
import { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

enum HiveConnectionItemStatus {
  PENDING = 'pending',
  CONNECTED = 'connected',
  REJECTED = 'rejected'
}

const HiveChatConnectionsMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 32px;
  padding: 0 40px;
`;

interface HiveChatConnectionsMessageProps {
  message: HiveConnectionsMessage;
  addMessage: (message: HiveMessage) => void;
}

export const HiveChatConnectionsMessage = ({message, addMessage}: HiveChatConnectionsMessageProps) => {

  const [maxItemHeight, setMaxItemHeight] = useState(0);
  const [chosenConnectionIndex, setChosenConnectionIndex] = useState<number>();

  const handleItemHeight = (height: number) => {
    setMaxItemHeight((currentHeight) => {
      if (height > currentHeight) {
        return height;
      }
      return currentHeight;
    })
  }

  // const viewProfile = async (userId: string) => {
  //   const response = await sendRequest({
  //     method: 'get',
  //     url: `/profile/user/${userId}`
  //   })
  //   return response;
  // }

  const handleConnectionSelected = async (itemIndex: number) => {
    // const userId = message.connections[itemIndex].user.id;
    // const profile = await viewProfile(userId);
    if (chosenConnectionIndex != null) {
      return;
    }
    setChosenConnectionIndex(itemIndex);
    const connectingMessage: HiveTextMessage = {
      text: 'Great, I\'ll reach out and let you know what I hear! Is there anything else you need?',
      isUser: false
    }
    await addMessage(connectingMessage);
  }

  return (
    <HiveChatConnectionsMessageContainer>
      {
        message.connections.map((connection, index) => {
          let itemStatus = HiveConnectionItemStatus.PENDING;
          if (chosenConnectionIndex != null) {
            if (chosenConnectionIndex === index) {
              itemStatus = HiveConnectionItemStatus.CONNECTED;
            } else {
              itemStatus = HiveConnectionItemStatus.REJECTED;
            }
          }
          return (
            <HiveChatConnectionItem
              connection={connection}
              index={index}
              handleItemHeight={handleItemHeight}
              height={maxItemHeight}
              handleConnect={handleConnectionSelected}
              status={itemStatus}
            />
          )
        })
      }
    </HiveChatConnectionsMessageContainer>
  )

}

const HiveChatConnectionContainer = styled.div`

  .chat-connection {
    width: 300px;
    border: 2px solid ${colors.mediumGray};
    border-radius: 10px;
    padding: 16px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .chat-connection-avatar {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }

    .chat-connection-title {
      font-size: 20px;
      font-family: ${fonts.secondary};
      font-weight: 500;
      width: 100%;
      text-align: center;
    }

    .chat-connection-reason {
      color: rgba(0, 0, 0, .85);
      background-color: rgba(241, 242, 245, .75);
      padding: 16px;
      font-size: 16px;
      margin-top: 16px;
      border-radius: 2px;
    }

    .chat-connection-action {
      margin-top: 8px;
    }
  }
`;

interface HiveChatConnectionProps {
  connection: HiveChatConnection;
  index: number;
  handleItemHeight: (height: number) => void;
  height: number;
  handleConnect: (index: number) => void;
  status: HiveConnectionItemStatus;
}

export const HiveChatConnectionItem = (props: HiveChatConnectionProps) => {

  const {connection, index, handleItemHeight, height} = props;

  const itemRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useLayoutEffect(() => {
    if (itemRef.current.offsetHeight > 0) {
      handleItemHeight(itemRef.current.offsetHeight);
    }
  }, []);

  const [connectionStyles] = useSpring(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1
      },
      delay: index * 200,
      config: {
        tension: 3,
        friction: 0,
        clamp: true
      }
    }),
    []
  )

  return (
    <HiveChatConnectionContainer>
      <animated.div style={connectionStyles}>
        <div
          className="chat-connection"
          ref={itemRef}
          style={{
            height: height > 0 ? height : 'auto'
          }}
        >
          <div>
            {/* <div
              className="chat-connection-avatar"
            >
              <Avatar size={104} icon={<UserOutlined />} />
            </div> */}
            <div
              className="chat-connection-title"
            >
              {connection.user.firstName} {connection.user.lastName}
            </div>
            <div
              className="chat-connection-reason"
            >
              {connection.reason}
            </div>
          </div>
          {/* <div>
            <div
              className="chat-connection-action"
            >
              <HiveChatConnectionButton
                index={index}
                status={status}
                handleConnect={handleConnect}
              />
            </div>
          </div> */}
        </div>
      </animated.div>
    </HiveChatConnectionContainer>
  )

}

// interface HiveChatConnectionButtonProps {
//   index: number;
//   handleConnect: (index: number) => void;
//   status: HiveConnectionItemStatus;
// }

// const HiveChatConnectionButton = ({index, status, handleConnect}: HiveChatConnectionButtonProps) => {

//   const [isHovering, setIsHovering] = useState(false);

//   let connectButtonText = 'Connect';
//   if (status === HiveConnectionItemStatus.CONNECTED) {
//     connectButtonText = 'Connected!';
//   }

//   const [buttonConnectedStyles, connectedApi] = useSpring(
//     () => ({
//       from: {
//         color: '#000',
//         borderColor: 'rgb(217, 217, 217)'
//       },
//       to: {
//         color: `${colors.primary}`,
//         borderColor: `${colors.primary}`
//       },
//       pause: true,
//       config: {
//         clamp: true
//       }
//     }),
//     []
//   )

//   const [buttonRejectedStyles, rejectedApi] = useSpring(
//     () => ({
//       from: {
//         opacity: 1,
//       },
//       to: {
//         opacity: 0
//       },
//       pause: true,
//       config: {
//         clamp: true
//       }
//     }),
//     []
//   )

//   useEffect(() => {
//     if (status === HiveConnectionItemStatus.CONNECTED) {
//       connectedApi.resume();
//     }
//     if (status === HiveConnectionItemStatus.REJECTED) {
//       rejectedApi.resume();
//     }
//   }, [status])

//   let buttonStyles = {
//     height: 'auto',
//     padding: '10px 20px',
//     backgroundColor: '#ffffff',
//     boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
//     width: '100%',
//     border: '1px solid #d9d9d9',
//     borderRadius: '10px',
//     cursor: 'pointer',
//     transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)'
//   }

//   if (isHovering) {
//     buttonStyles = {
//       ...buttonStyles,
//       ...{
//         color: '#49ab9c',
//         border: '1px solid #49ab9c'
//       }
//     }
//   }

//   buttonStyles = {
//     ...buttonStyles,
//     ...buttonConnectedStyles,
//     ...buttonRejectedStyles
//   }

//   return (
//     <animated.button
//       style={buttonStyles}
//       onClick={() => handleConnect(index)}
//       onMouseEnter={() => setIsHovering(true)}
//       onMouseLeave={() => setIsHovering(false)}
//     >
//       <span
//         style={{
//           display: 'inline-block',
//           fontSize: '16px',
//           fontFamily: `${fonts.secondary}`,
//           fontWeight: '700',
//           lineHeight: '1.5'
//         }}
//       >
//         {connectButtonText}
//       </span>
//     </animated.button>
//   )

// }