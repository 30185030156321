import { CloseCircleOutlined } from "@ant-design/icons";
import { ConfigElementProps, SlateElement, SlateInputElement } from "@src/components/config/config-editor.types";
import { ConfigTag, InlineChromiumBugfix } from "@src/components/config/config-editor.utils";
import { StandardButton } from "@src/components/standard/button/button";
import { colors } from "@src/config/styles/variables";
import { Divider, Tooltip } from "antd";
import { useCallback, useState } from "react";
import { BaseSelection, Descendant, Editor, Element, Node, Path, Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, useSelected, withReact } from "slate-react";
import styled from "styled-components";
import { PromptFilterMap, PromptTemplate, PromptTemplateFilter, PromptTemplateInput } from "./prompts.types";
import { withInputs } from "./template-editor.config";

const parseTemplateString = (input: string): Array<{ type: string, content: string }> => {
  const regex = /({{(.*?)}})|([^{}]+)/gs; // Added 's' flag to allow . to match newline characters
  const result = [];

  let match;
  while (match = regex.exec(input)) {
    if (match[2]) {
      result.push({ type: 'input', content: `{{ ${match[2].trim()} }}` });
    } else if (match[3]) {
      result.push({ type: 'text', content: match[3] });
    }
  }

  return result;
}

interface PromptTemplateEditorProps {
  template: PromptTemplate;
  filters: PromptFilterMap;
  onUpdate: (updateTemplate: string) => void;
  setIsEditingTemplate: (isEditing: boolean) => void;
}

const PromptTemplateEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  white-space: pre-wrap;
`

export const PromptTemplateEditor = (
  {template, filters, setIsEditingTemplate, onUpdate}: PromptTemplateEditorProps
) => {

  return (
    <PromptTemplateEditorContainer>
      <PromptTemplateEditorInput
        template={template}
        filters={filters}
        onUpdate={onUpdate}
        setIsEditingTemplate={setIsEditingTemplate}
      />
    </PromptTemplateEditorContainer>
  )

}

const PromptTemplateEditorInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .prompt-template-editor {
    border: 1px solid ${colors.mediumGray};

    .prompt-template-editor-toolbar {
      padding: 4px 8px;
      background-color: ${colors.extraLightGray};
      border-bottom: 1px solid ${colors.mediumGray};

      .toolbar-section-label {
        min-width: 85px;
      }

      .prompt-template-editor-toolbar-vars {
        padding: 8px 0;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        border-bottom: 1px solid ${colors.lightGray};
      }
      
      .prompt-template-editor-toolbar-filters {
        padding: 8px 0;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }
    }

    .prompt-template-editor-input {
      background-color: ${colors.lightGray};
    }

    &.is-editing {
      border-color: ${colors.darkGray};
      background-color: ${colors.white};

      .prompt-template-editor-input {
        background-color: ${colors.white};
      }
    }
  }
`

interface PromptTemplateEditorInputProps {
  template: PromptTemplate;
  filters: PromptFilterMap;
  onUpdate: (updateTemplate: string) => void;
  setIsEditingTemplate: (isEditing: boolean) => void;
}

const PromptTemplateEditorInput = (
  {template, filters, onUpdate, setIsEditingTemplate}: PromptTemplateEditorInputProps
) => {

  const [editor] = useState(() => {
    return (
      withInputs(
        withReact(
          withHistory(
            createEditor()
          )
        )
      )
    )
  })

  const [isEditing, setIsEditing] = useState(true);

  const parsedTemplate = parseTemplateString(template.template);

  const getInitialElements = () => {
    const values: Array<any> = [];
    
    let currentChildren: Array<any> = [];

    parsedTemplate.forEach((item) => {

      if (item.type === 'input') {
        const itemContent = item.content.split('{{')[1].split('}}')[0].trim();
        const splitContent = itemContent.split('|')
        const inputKey = splitContent[0].trim();
        let inputFilterKey: string;
        const input = {
          ...template.inputs[inputKey],
          key: inputKey
        }
        let filter;
        if (splitContent[1]) {
          inputFilterKey = splitContent[1].trim();
          const inputFilter = filters[inputFilterKey];
          if (inputFilter) {
            filter = {
              key: inputFilterKey,
              label: inputFilter.label
            }
          }
        }
        currentChildren.push(...[
          {
            type: 'text',
            text: ''
          },
          {
            type: 'input',
            input: input,
            filter: filter,
            children: [{
              text: ''
            }]
          },
          {
            type: 'text',
            text: ' '
          }
        ]);
      } else {
        if (currentChildren.length) {
          values.push({
            children: [...currentChildren]
          })
        }
        currentChildren = [];
        currentChildren.push({
          text: item.content
        })
      }
    })

    if (currentChildren.length) {
      values.push({
        children: [...currentChildren]
      })
    }

    return values;
  }

  const initialValue: Array<Descendant> = [{
    type: 'text',
    children: [
      ...getInitialElements()
    ]
  }]

  const onRemoveInput = () => {
    setSelectedInputElement(undefined);
  }

  const renderElement = useCallback((props: any) => {
    const { attributes, children, element } = props;
    switch (element.type) {
      case 'input':
        const elementProps = {
          ...props,
          onRemove: onRemoveInput,
          editor: editor
        }
        return <PromptEditorInputElement {...elementProps} />
      default:
        return <div {...attributes}>{children}</div>
    }
  }, [])

  
  const startEditing = () => {
    setIsEditingTemplate(true);
    setIsEditing(true);
  }
  
  const saveTemplate = () => {
    setIsEditing(false);
    setIsEditingTemplate(false);
    const updatedTemplate = getTemplateString();
    onUpdate(updatedTemplate);
  }
  
  const inputList = Object.entries(template.inputs);
  
  const handleEditableChange = (descendants: Array<Descendant>) => {
    // console.log(descendants);
  }

  const addInput = (inputKey: string) => {
    const input = template.inputs[inputKey];
    if (input) {
      const insertedNode = editor.insertInput({
        label: input.label,
        key: inputKey
      })
      if (insertedNode) {
        setSelectedInputElement(insertedNode as SlateInputElement);
      }
    }
  }
  
  const [selectedInputElement, setSelectedInputElement] = useState<SlateInputElement | undefined>();
  const handleSelectionChange = (selection: BaseSelection) => {
    if (!selection?.focus) {
      setSelectedInputElement(undefined);
      return;
    }
    const path = Path.parent(selection.focus.path)
    if (!path) {
      setSelectedInputElement(undefined);
      return;
    }
    const element = Node.get(editor, path) as SlateElement;
    if (element.type === 'input') {
      setSelectedInputElement(element);
      return;
    }
    setSelectedInputElement(undefined);
  }

  const addFilterToInput = (filterKey: string) => {
    const filter = filters[filterKey];
    if (!selectedInputElement || !filter) {
      return;
    }
    let filterUpdate: any = {
      filter: undefined
    }
    if (selectedInputElement.filter?.key !== filterKey) {
      filterUpdate.filter = {
        key: filterKey,
        label: filter.label
      };
    }
    Transforms.setNodes(editor, filterUpdate, {
      match: (node: Node) => {
        const element = node as SlateElement;
        if (element.type === 'input' && element.input) {
          return element.input.key === selectedInputElement.input.key;
        }
        return false;
      }
    })
    Transforms.deselect(editor);
    setSelectedInputElement(undefined);
  }

  const getInputTemplateString = (
    input: PromptTemplateInput,
    filter?: PromptTemplateFilter
  ) => {
    let inputString = input.key;
    if (filter) {
      inputString = `${input.key} | ${filter.key}`
    }
    return `{{ ${inputString} }}`
  }

  const getTemplateString = () => {
    const nodes = editor.children as Array<Node>;

    const getNodeString = (node: Node): string => {
      if (Element.isElement(node)) {
        const element = node as Element;
        if (element.type === 'input') {
          return getInputTemplateString(element.input, element.filter);
        }
        if (element.children) {
          let elementText = element.children.map((child) => getNodeString(child)).join('');
          elementText += '\n';
          return elementText;
        }
      }
      return Node.string(node)
    }

    const template = nodes.map((node) => {
      return `${getNodeString(node)}\n`;
    })

    console.log(template.join(''))

    return template.join('');
      
  }

  return (
    <PromptTemplateEditorInputContainer>
      <div
        className={`prompt-template-editor ${isEditing && 'is-editing'}`}
      >
        {
          isEditing &&
            <div className="prompt-template-editor-toolbar">
              <div className="prompt-template-editor-toolbar-vars">
                <div className="toolbar-section-label">
                  Prompt Vars:
                </div>
                {
                  inputList.map(([inputKey, input]) => {
                    return (
                      <div
                        key={inputKey}
                      >
                        <PromptTemplateEditorToolbarItem
                          editor={editor}
                          label={input.label}
                          tooltip={input.tooltip}
                          isEnabled={true}
                          isActive={false}
                          onClick={() => addInput(inputKey)}
                        />
                      </div>
                    )
                  })
                }
              </div>
              <div className="prompt-template-editor-toolbar-filters">
                <div className="toolbar-section-label">
                  Filters:
                </div>
                {
                  Object.entries(filters).map(([filterKey, filter]) => {
                    const isFilterEnabled = selectedInputElement ? true : false;
                    const isFilterActive = isFilterEnabled &&
                      selectedInputElement?.filter?.key === filterKey
                    return (
                      <div key={filterKey}>
                        <PromptTemplateEditorToolbarItem
                          label={filter.label}
                          editor={editor}
                          isEnabled={selectedInputElement ? true : false}
                          isActive={isFilterActive}
                          tooltip={filter.tooltip}
                          onClick={() => addFilterToInput(filterKey)}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
        }
        <div
          className="prompt-template-editor-input"
        >
          <Slate
            editor={editor}
            onSelectionChange={handleSelectionChange}
            initialValue={initialValue}
            onChange={handleEditableChange}
          >
            <Editable
              readOnly={!isEditing}
              renderElement={renderElement}
              style={{
                padding: 8,
                outline: 'none'
              }}
            />
          </Slate>
        </div>
      </div>
      <div
        className="prompt-template-editor-actions"
      >
        {
          !isEditing &&
            <>
              <StandardButton
                onClick={startEditing}
              >
                Edit Prompt Template
              </StandardButton>
            </>
        }
        {
          isEditing &&
            <>
              <StandardButton
                type="primary"
                onClick={saveTemplate}
              >
                Save Changes
              </StandardButton>
            </>
        }
      </div>
      <Divider />
    </PromptTemplateEditorInputContainer>
  )

}

// Prompt Editor Elements

const PromptEditorInputElement = (props: ConfigElementProps) => {

  const {attributes, children, element, editor, onRemove} = props;

  const selected = useSelected();

  const inputElement = element as SlateInputElement;

  const color = selected ? colors.blue : colors.primary;

  const remove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    editor.removeInput(inputElement.input);
    if (onRemove) {
      onRemove();
    }
  };

  return (
    <span {...attributes} contentEditable={false}>
      <InlineChromiumBugfix />
      <ConfigTag
        color={color}
        closable
        onClose={remove}
      >
        <span
          className="ant-tag-content"
        >
          {inputElement.input.label}
          {
            inputElement.filter &&
              <>
                &nbsp;&nbsp;|&nbsp;&nbsp;<b>{inputElement.filter.label}</b>
              </>
          }
        </span>
      </ConfigTag>
      <div
        style={{
          display: 'none'
        }}
      >
        {children}
      </div>
      <InlineChromiumBugfix />
    </span>
  )
}

const PromptTemplateEditorToolbarItemContainer = styled.div`
  .toolbar-item {
    padding: 2px 6px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${colors.mediumGray};

    &:hover {
      background-color: ${colors.mediumGray};
    }

    &.disabled {
      color: ${colors.darkGray};
      cursor: default;

      &:hover {
        background-color: ${colors.lightGray};
      }
    }

    &.active {
      font-weight: bold;
    }
  }
`

interface PromptEditorToolbarItemProps {
  label: string;
  editor: Editor;
  isEnabled: boolean;
  isActive: boolean;
  tooltip?: string;
  onClick: () => void;
}

const PromptTemplateEditorToolbarItem = (props: PromptEditorToolbarItemProps) => {

  const {label, tooltip, isEnabled, isActive, onClick} = props;

  const handleClick = () => {
    if (!isEnabled) {
      return;
    }
    onClick();
  }

  return (
    <PromptTemplateEditorToolbarItemContainer>
      <Tooltip title={tooltip}>
        <div
          className={`toolbar-item ${isEnabled ? '' : 'disabled'} ${isActive ? 'active' : ''}`}
          onClick={handleClick}
        >
          {label}
          {
            isActive &&
              <span>
                &nbsp;&nbsp;<CloseCircleOutlined />
              </span>
          }
        </div>
      </Tooltip>
    </PromptTemplateEditorToolbarItemContainer>
  )

}
