import styled from "styled-components"
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";

import { StandardCard } from "@src/components/standard/card/card"
import { Form, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { StandardButton } from "@src/components/standard/button/button";
import { StandardInput } from "@src/components/standard/form/input";
import { useEffect, useState } from "react";
import { StandardForm } from "@src/components/standard/form/form";
import { signInWithEmail, useFirebaseAuthentication } from "@src/utils/firebase/firebase.utils";

import logo from '@src/assets/images/pairup-logo-horizontal.png';

export const AuthFormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 15vh;
  margin-bottom: 100px;

  .auth-form-header {
    display: flex;
    justify-content: center;
    img {
      width: 200px;
      max-width: 100%;
    }
    margin-bottom: 32px;
  }

  .auth-form-inputs {
    margin-bottom: 32px;
  }
`

export const AuthPage = () => {

  const navigate = useNavigate();
  
  const [hasCheckedUser, setHasCheckedUser] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const currentUser = useFirebaseAuthentication();
  
  useEffect(() => {
    if (currentUser) {
      goToHive();
      return
    }
    setHasCheckedUser(true);
  }, [currentUser])
  
  const [form] = useForm();

  const goToHive = () => {
    navigate('/hive');
  }

  const signIn = async () => {
    setIsSigningIn(true);
    const { email, password } = form.getFieldsValue();
    try {
      await signInWithEmail(email, password);
      goToHive();
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/user-not-found') {
          message.error('The username and password combination was not found.');
        } else {
          message.error('The username and password combination was not found.');
        }
      } else {
        message.error('There was a problem signing you in.');
      }
      setIsSigningIn(false);
    }
  }

  return (
    <div>
      {
        hasCheckedUser &&
          <AuthFormContainer>
            <StandardCard>
              <div
                className="auth-form-header"
              >
                <img
                  src={logo}
                  alt="PairUp Logo"
                />
              </div>
              <StandardForm
                form={form}
                className="auth-form-inputs"
                layout="vertical"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    signIn();
                  }
                }}
              >
                <Form.Item
                  name="email"
                  label="Email"
                >
                  <StandardInput />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                >
                  <StandardInput
                    type="password"
                  />
                </Form.Item>
              </StandardForm>
              <StandardButton
                block
                type="primary"
                onClick={signIn}
                disabled={isSigningIn}
                loading={isSigningIn}
              >
                Sign In
              </StandardButton>
            </StandardCard>
          </AuthFormContainer>
      }
    </div>
  )

}