import { colors, fonts } from "@src/config/styles/variables";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { AppRouter } from "./routes";
import { getFirebaseAuth, logout } from "./utils/firebase/firebase.utils";
import { useEffect, useState } from "react";
import { sendRequest } from "./utils/request/request.utils";
import { UserContext, UserState } from "./context/user";
import { LoadingScreen } from "./components/loading/loading";

function App() {

  const auth = getFirebaseAuth();
  const [firebaseUser, loading] = useAuthState(auth);

  const [user, setUser] = useState<UserState>();

  const setUserData = (userData: Partial<UserState>) => {
    if (!user) {
      return;
    }
    const updatedUser = {
      ...user,
      ...userData
    }
    setUser(updatedUser);
  }

  useEffect(() => {
    if (firebaseUser && !user) {
      getUser();
    }
  }, [firebaseUser])

  const getUser = async () => {
    try {
      const response = await sendRequest({
        method: 'get',
        url: '/user'
      })
      let userData = {
        ...response
      }
      if (userData.isSuperAdmin) {
        const selectedAccountId = localStorage.getItem('selectedAccountId');
        if (selectedAccountId) {
          userData.accountId = selectedAccountId;
        }
      }
      setUser(userData);
    } catch (err) {
      await logout();
      window.location.href = '/';
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: colors.lightGray,
          colorPrimary: colors.primary,
          borderRadius: 10,
        },
        components: {
          Button: {
            fontFamily: fonts.secondary
          }
        }
      }}
    >
      {
        loading &&
          <LoadingScreen />
      }
      {
        !loading &&
          <UserContext.Provider value={[user, setUserData]}>
            <RouterProvider router={AppRouter} />
          </UserContext.Provider>
      }
    </ConfigProvider>
  );
}

export default App;
