import { FirebaseError } from "firebase/app";
import { useNavigate, useSearchParams } from "react-router-dom";

import { StandardCard } from "@src/components/standard/card/card"
import { Form, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { StandardButton } from "@src/components/standard/button/button";
import { StandardInput } from "@src/components/standard/form/input";
import { useState } from "react";
import { StandardForm } from "@src/components/standard/form/form";
import { signInWithEmail } from "@src/utils/firebase/firebase.utils";

import logo from '@src/assets/images/pairup-logo-horizontal.png';
import { AuthFormContainer } from "./auth";
import { sendRequest } from "@src/utils/request/request.utils";

const decodeBase64url = (base64url: string): string => {
  // Convert Base64url to Base64
  let base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
  // Add padding if required
  const padding = 4 - (base64.length % 4);
  if (padding !== 4) {
      base64 += '='.repeat(padding);
  }
  
  // Decode from Base64
  return atob(base64);
}

export const ActivatePage = () => {

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const encodedEmail = searchParams.get('e') as string;
  
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [activationCode] = useState(searchParams.get('code'));
  const [email] = useState(decodeBase64url(encodedEmail));

  const initialValues = {
    email: email
  }
  
  const [form] = useForm();

  const goToHive = () => {
    navigate('/hive');
  }

  const activateUser = async () => {
    setIsSigningIn(true);
    const { password, confirmPassword } = form.getFieldsValue();
    // activate
    try {
      if (password !== confirmPassword) {
        throw new Error('The passwords do not match!');
      }
      await sendRequest({
        method: 'post',
        url: '/user/activate',
        body: {
          password: password,
          code: activationCode
        }
      })
    } catch (err: unknown) {
      const error: any = err;
      message.error(error.message);
      setIsSigningIn(false);
      return;
    }
    // // login
    try {
      await signInWithEmail(email, password);
      goToHive();
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/user-not-found') {
          message.error('The username and password combination was not found.');
        } else {
          message.error('The username and password combination was not found.');
        }
      } else {
        message.error('There was a problem signing you in.');
      }
      setIsSigningIn(false);
    }
  }

  return (
    <div>
      <AuthFormContainer>
        <StandardCard>
          <div
            className="auth-form-header"
          >
            <img
              src={logo}
              alt="PairUp Logo"
            />
          </div>
          <StandardForm
            form={form}
            className="auth-form-inputs"
            initialValues={initialValues}
            layout="vertical"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                activateUser();
              }
            }}
          >
            <Form.Item
              name="email"
              label="Email"
            >
              <StandardInput
                disabled
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
            >
              <StandardInput
                type="password"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
            >
              <StandardInput
                type="password"
              />
            </Form.Item>
          </StandardForm>
          <StandardButton
            block
            type="primary"
            onClick={activateUser}
            disabled={isSigningIn}
            loading={isSigningIn}
          >
            Sign In
          </StandardButton>
        </StandardCard>
      </AuthFormContainer>
    </div>
  )

}