import { RightOutlined } from "@ant-design/icons";
import { StandardTextArea } from "@src/components/standard/form/input";
import { colors } from "@src/config/styles/variables";
import { Button, Form, message } from "antd"
import { useForm } from "antd/es/form/Form";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import styled from "styled-components"

const HiveChatInputContainer = styled.div`
  background: linear-gradient(180deg, rgba(240, 242, 245, 0.1) 0, ${colors.lightGray} 40%);
  padding-top: 24px;
  padding-bottom: 36px;
  max-width: 1080px;
  margin: 0 auto;

  .chat-input-text {
    flex: 1;

    .ant-input {
      padding: 12px 16px;
    }
  }

  .ant-btn {
    width: 50px;
    height: 50px;
  }

  .chat-input-send {
    margin-inline-end: 0;

    .anticon {
      vertical-align: -0.25em;
    }
  }
`

interface HiveChatInputProps {
  sendMessage: (text: string) => Promise<void>;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const HiveChatInput = ({sendMessage, isDisabled, isLoading}: HiveChatInputProps) => {

  const [formRef] = useForm();

  const [messageText, setMessageText] = useState<string>('');

  const updateMessageText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageText(event.target.value)
  }

  const onInputEnter = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (isDisabled) {
        return;
      }
      e.preventDefault();
      try {
        await sendMessage(messageText);
        formRef.resetFields();
      } catch (err: any) {
        message.error(err.message);
      }
    }
  }

  return (
    <HiveChatInputContainer>
      <Form
        form={formRef}
        onFinish={() => sendMessage(messageText)}
        layout="inline"
      >
        <Form.Item
          name="message"
          className="chat-input-text"
        >
          <StandardTextArea
            onChange={updateMessageText}
            autoSize
            onKeyDown={onInputEnter}
            placeholder={
              'Type message here'
            }
            autoFocus
            disabled={isDisabled}
          />
        </Form.Item>
        <Form.Item
          className="chat-input-send"
        >
          <Button
            htmlType="submit"
            icon={
              <RightOutlined
                style={{
                  fontSize: '18px',
                  color: '#2A9D8F'
                }}
              />
            }
            disabled={isDisabled}
            loading={isLoading}
          />
        </Form.Item>
      </Form>
    </HiveChatInputContainer>
  )
}