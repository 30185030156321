import { colors } from "@src/config/styles/variables";
import { Layout } from "antd";
import { Outlet } from "react-router";
import styled from "styled-components/macro";

const AuthLayout = styled(Layout)`
  min-height: 100vh;
  background-color: ${colors.lightGray};
`

const AuthContentLayout = styled(Layout)`
  min-height: 100vh;
  align-items: center;
`

const AuthContent = styled(Layout.Content)`
  width: 100%;
`

export const AuthPageContainer = () => {

  return (
    <AuthLayout>
      <AuthContentLayout>
        <AuthContent>
          <Outlet />
        </AuthContent>
      </AuthContentLayout>
    </AuthLayout>
  )

}