import { StandardInput } from "@src/components/standard/form/input";
import { Collapse, CollapseProps, Form, message } from "antd";
import { useContext, useState } from "react";
import styled from "styled-components";
import { ImportConfigFieldList } from "./import-config-editor-fields";
import { ImportConfigHandlers } from "./import-config-editor-handlers";
import { DEFAULT_HANDLER, ImportConfig, ImportConfigField, ImportConfigFields, ImportConfigHandler } from "./import-config.types";
import { StandardButton } from "@src/components/standard/button/button";
import { sendRequest } from "@src/utils/request/request.utils";
import { UserContext } from "@src/context/user";

const ImportConfigEditorContainer = styled.div``

interface ImportConfigEditorProps {
  config?: ImportConfig;
  setConfig: (config: ImportConfig) => void;
}

export const ImportConfigEditor = (props: ImportConfigEditorProps) => {

  const [user] = useContext(UserContext);

  const { config, setConfig } = props;

  let initialFields: ImportConfigFields = {};
  if (config?.options.fields) {
    initialFields = {...config.options.fields};
  }

  let initialHandlers: Array<ImportConfigHandler> = [];
  if (config?.options.fields) {
    initialHandlers = [...config.options.handlers];
  }

  const [configName, setConfigName] = useState(config?.name ? config.name : '');
  const [fields, setFields] = useState<ImportConfigFields>(initialFields);
  const [handlers, setHandlers] = useState<Array<ImportConfigHandler>>(initialHandlers);

  const updateConfigName = (event: any) => {
    setConfigName(event.target.value);
  }

  // FIELDS

  const DEFAULT_FIELD: ImportConfigField = {
    column: 0,
    required: true
  }

  const addField = (key: string) => {
    if (fields[key]) {
      return;
    }
    setFields((currentFields) => {
      return {
        ...currentFields,
        [key]: DEFAULT_FIELD
      }
    })
  }

  const removeField = (key: string) => {
    setFields((currentFields) => {
      const updatedFields = {...currentFields};
      delete updatedFields[key];
      return updatedFields;
    })
  }

  const updateFieldRequired = (key: string, isRequired: boolean) => {
    setFields((currentFields) => {
      const updatedFields = {...currentFields};
      updatedFields[key].required = isRequired;
      return updatedFields;
    })
  }
  
  const updateFieldColumn = (key: string, column: number) => {
    setFields((currentFields) => {
      const updatedFields = {...currentFields};
      updatedFields[key].column = column;
      return updatedFields;
    })
  }

  // HANDLERS

  const addHandler = () => {
    setHandlers((currentHandlers) => {
      const updatedHandlers = [...currentHandlers];
      updatedHandlers.push({...DEFAULT_HANDLER})
      return updatedHandlers;
    })
  }
  
  const saveHandler = (index: number, handler: ImportConfigHandler) => {
    setHandlers((currentHandlers) => {
      const updatedHandlers = [...currentHandlers];
      updatedHandlers[index] = {...handler};
      return updatedHandlers;
    })
  }

  // SAVE

  const saveConfig = async () => {
    if (config) {
      await sendRequest({
        method: 'post',
        url: '/importer/config/edit',
        body: {
          importConfigId: config.id,
          name: configName,
          options: {
            fields: fields,
            handlers: handlers
          }
        }
      })
      message.success('Config Updated!');
    } else {
      const createdConfig = await sendRequest({
        method: 'post',
        url: '/importer/config/create',
        body: {
          name: configName,
          accountId: user?.accountId,
          options: {
            fields: fields,
            handlers: handlers
          }
        }
      })
      setConfig(createdConfig);
      message.success('Config Created!');
    }
  }

  const collapsePanels: CollapseProps['items'] = [
    {
      key: 'fields',
      label: 'Configuration Fields',
      children: (
        <ImportConfigFieldList
          fields={fields}
          addField={addField}
          removeField={removeField}
          updateFieldRequired={updateFieldRequired}
          updateFieldColumn={updateFieldColumn}
        />
      ),
    },
    {
      key: 'handlers',
      label: 'Configuration Handlers',
      children: (
        <ImportConfigHandlers
          fields={fields}
          handlers={handlers}
          addHandler={addHandler}
          saveHandler={saveHandler}
        />
      ),
    }
  ];

  return (
    <ImportConfigEditorContainer>
      <Form
        layout="vertical"
        initialValues={config}
      >
        <Form.Item
          name="name"
          label="Configuration Name"
        >
          <StandardInput
            onChange={updateConfigName}
            value={configName}
          />
        </Form.Item>
      </Form>
      <Collapse
        accordion
        defaultActiveKey={['fields']}
        items={collapsePanels}
      />
      <StandardButton
        type="primary"
        style={{
          marginTop: '12px'
        }}
        onClick={saveConfig}
      >
        Save Import Config
      </StandardButton>
    </ImportConfigEditorContainer>
  )

}