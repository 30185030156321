import styled from "styled-components";
import { ImportConfigField, ImportConfigFields } from "./import-config.types";
import { StandardButton } from "@src/components/standard/button/button";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Switch } from "antd";
import { StandardInput } from "@src/components/standard/form/input";
import { useState } from "react";
import { fonts } from "@src/config/styles/variables";

const ImportConfigFieldsContainer = styled.div`
  .import-config-fields {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

interface ImportConfigFieldsProps {
  fields: ImportConfigFields;
  addField: (key: string) => void;
  removeField: (key: string) => void;
  updateFieldRequired: (key: string, isRequired: boolean) => void;
  updateFieldColumn: (key: string, column: number) => void;
}

export const ImportConfigFieldList = (props: ImportConfigFieldsProps) => {

  const { fields, addField, removeField, updateFieldRequired, updateFieldColumn } = props;

  return (
    <ImportConfigFieldsContainer>
      <div
        className="import-config-fields"
      >
        {
          Object.entries(fields).map(([fieldKey, field]) => {
            return (
              <ImportConfigFieldItem
                key={fieldKey}
                field={field}
                fieldKey={fieldKey}
                removeField={removeField}
                updateFieldRequired={updateFieldRequired}
                updateFieldColumn={updateFieldColumn}
              />
            )
          })
        }
      </div>
      <Divider />
      <ImportConfigFieldAdd
        addField={addField}
      />
    </ImportConfigFieldsContainer>
  )

}

const ImportConfigFieldAddContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  .field-add-input-container {
    width: 100%;
    max-width: 300px;
  }
`

interface ImportConfigFieldAddProps {
  addField: (key: string) => void;
}

const ImportConfigFieldAdd = ({addField}: ImportConfigFieldAddProps) => {

  const [fieldKey, setFieldKey] = useState('');

  const updateFieldKey = (e: any) => {
    setFieldKey(e.target.value);
  }

  const addConfigField = () => {
    addField(fieldKey);
    setFieldKey('');
  }

  return (
    <ImportConfigFieldAddContainer>
      <div
        className="field-add-input-container"
      >
        <StandardInput
          value={fieldKey}
          onChange={updateFieldKey}
          placeholder="Field Key"
        />
      </div>
      <StandardButton
        size="small"
        icon={<PlusOutlined />}
        onClick={addConfigField}
      >
        Add Field
      </StandardButton>
    </ImportConfigFieldAddContainer>
  )

}

const ImportConfigFieldContainer = styled.div`
  .ant-form-item {
    display: flex;
    align-items: center;
  }

  .import-field-key {
    font-family: ${fonts.secondary};
    font-size: 16px;
    font-weight: 600;
    width: 200px;
  }
`

interface ImportConfigFieldProps {
  field: ImportConfigField;
  fieldKey: string;
  removeField: (key: string) => void;
  updateFieldRequired: (key: string, isRequired: boolean) => void;
  updateFieldColumn: (key: string, column: number) => void;
}

export const ImportConfigFieldItem = (props: ImportConfigFieldProps) => {

  const { field, fieldKey, removeField, updateFieldRequired, updateFieldColumn } = props;

  const updateColumn = (e: any) => {
    const column = parseInt(e.target.value);
    if (Number(column)) {
      updateFieldColumn(fieldKey, column);
    }
  }

  return (
    <ImportConfigFieldContainer>
      <Form
        layout="inline"
        initialValues={field}
      >
        <Form.Item>
          <div
            className="import-field-key"
          >
            {fieldKey}
          </div>
        </Form.Item>
        <Form.Item
          name="column"
        >
          <StandardInput
            onChange={updateColumn}
            prefix="Column #"
            type="number"
            size="small"
          />
        </Form.Item>
        <Form.Item
          name="required"
          valuePropName="checked"
        >
          <Switch
            onChange={(isRequired: boolean) => updateFieldRequired(fieldKey, isRequired)}
            checkedChildren="required"
            unCheckedChildren="optional"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            size="large"
            icon={<DeleteOutlined />}
            onClick={() => removeField(fieldKey)}
            danger
          />
        </Form.Item>
      </Form>
    </ImportConfigFieldContainer>
  )

}