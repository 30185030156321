import { AlignLeftOutlined, ApartmentOutlined, BookOutlined, ControlOutlined, DeploymentUnitOutlined, DiffOutlined, EyeOutlined, ImportOutlined, MonitorOutlined, OrderedListOutlined } from "@ant-design/icons";
import { Layout, Menu, MenuProps, Modal, SiderProps, Tag, message } from "antd";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { StandardButton } from "@src/components/standard/button/button";
import { colors } from "@src/config/styles/variables";
import { getCurrentUserToken, getFirebaseAuth, logout } from "@src/utils/firebase/firebase.utils";

import logo from '@src/assets/images/pairup-logo-horizontal.png';
import { UserContext } from "@src/context/user";
import { Account } from "@src/types/account.types";
import { sendRequest } from "@src/utils/request/request.utils";
import { useContext, useEffect, useState } from "react";
import { AccountSelectItem, AccountSelector } from "../admin/account-selector";

const LayoutSiderStyled = styled(Layout.Sider)`
  background: ${colors.white} !important;

  .ant-layout-sider-trigger {
    background: ${colors.darkGray};
  }

  .layout-sider-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    position: relative;
    box-shadow: 0px 1px 2px rgba(29, 29, 29, 0.05);

    a, img {
      height: 40px;
    }
  }

  .layout-sider-menu {
    flex: 1;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu-item {
      margin-bottom: 4px;
      height: 42px;
      line-height: 42px;
    }

    .layout-sider-menu-logout {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;

      .admin-account-display {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }
    }
  }
`

interface AccountMap {
  [accountId: string]: Account
}

export const LayoutSider = ({children, ...props}: SiderProps) => {

  return (
    <LayoutSiderStyled {...props}>
      <div
        className="layout-sider-logo"
      >
        <Link to="/">
          <img
            alt="PairUp Logo"
            src={logo}
          />
        </Link>
      </div>
      <div
        className="layout-sider-menu"
      >
        {children}
      </div>
    </LayoutSiderStyled>
  )

}

export const SiderMenu = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useContext(UserContext);

  const [accounts, setAccounts] = useState<AccountMap>();
  const [isChangingAccount, setIsChangingAccount] = useState(false);
  
  const [isSendingDemoInvite, setIsSendingDemoInvite] = useState(false);

  useEffect(() => {
    if (!accounts && user?.isSuperAdmin) {
      getAccounts();
    }
  }, [user]);

  const getAccounts = async () => {
    const response = await sendRequest({
      method: 'get',
      url: '/account/list'
    })
    const accountMap: AccountMap = {};
    response.forEach((account: Account) => {
      accountMap[account.id] = account;
    })
    setAccounts(accountMap);
  }


  let pageKey = 'dashboard';
  if (location) {
    const pathParts = location.pathname.split('/');
    if (pathParts.length > 1) {
      pageKey = location.pathname.split('/')[1];
    }
  }

  const items: MenuProps['items'] = [
    {
      label: 'Connect',
      key: 'hive',
      icon: <ApartmentOutlined />,
    },
  ];

  if (user && user.isSuperAdmin) {
    items.push({
      label: 'Import Configs',
      key: 'import',
      icon: <ImportOutlined />,
    })
    // items.push({
    //   label: 'Room Invite Analysis',
    //   key: 'analyze',
    //   icon: <MonitorOutlined />,
    // })
    // items.push({
    //   label: 'Topic Management',
    //   key: 'topics',
    //   icon: <BookOutlined />,
    // })
    // items.push({
    //   label: 'Subjects',
    //   key: 'subjects',
    //   icon: <DeploymentUnitOutlined />,
    // })
    items.push({
      label: 'Integrations',
      key: 'integrations',
      icon: <ControlOutlined />,
    })
    items.push({
      label: 'Query Debug',
      key: 'debug',
      icon: <EyeOutlined />,
    })
    items.push({
      label: 'Query Log',
      key: 'log',
      icon: <AlignLeftOutlined />,
    })
    items.push({
      label: 'Prompt Editor',
      key: 'prompts',
      icon: <DiffOutlined />,
    })
    items.push({
      label: 'Queue Monitor',
      key: 'queue',
      icon: <OrderedListOutlined />,
    })
  }

  const authQueueUser = async () => {
    const token = await getCurrentUserToken();
    if (!token) {
      message.error('There is no user token');
    }
    const result = await sendRequest({
      method: 'post',
      url: '/queue/verify',
      allowCredentials: true
    })
    if (result?.success) {
      return true;
    }
  }

  const onClick: MenuProps['onClick'] = async (e) => {
    if (e.key === 'queue') {
      const authed = await authQueueUser();
      if (authed) {
        window.location.href = `${process.env.REACT_APP_API_URL}/queues`
      }
      return;
    }
    const key = e.key;
    navigate(`/${key}`);
  };

  const signOut = async () => {
    await logout();
    navigate('/');
  }

  const sendDemoInvite = async () => {
    if (isSendingDemoInvite) {
      return;
    }
    setIsSendingDemoInvite(true);
    try {
      await sendRequest({
        method: 'post',
        url: '/room/demo/invite'
      })
    } catch (err) {
      console.log(err)
    }
    setIsSendingDemoInvite(false);
  }

  const openChangeAccount = () => {
    setIsChangingAccount(true);
  }

  const closeChangeAccount = () => {
    setIsChangingAccount(false);
  }

  const changeAccount = async (account: AccountSelectItem) => {
    localStorage.setItem('selectedAccountId', account.value);
    setUser({
      accountId: account.value
    })
    window.location.reload();
  }

  return (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[pageKey]}
        mode="vertical"
        items={items}
      />
      <div
        className="layout-sider-menu-logout"
      >
        {
          user?.isSuperAdmin &&
            <>
              {
                accounts && user?.accountId && accounts[user.accountId] &&
                  <div
                    className="admin-account-display"
                  >
                    <Tag>
                      {accounts[user.accountId].name}
                    </Tag>
                  </div>
              }
              <div>
                <StandardButton
                  block
                  type="dashed"
                  onClick={sendDemoInvite}
                  loading={isSendingDemoInvite}
                  disabled={isSendingDemoInvite}
                >
                  Send Demo Invite
                </StandardButton>
                <br /><br />
                <StandardButton
                  block
                  type="primary"
                  ghost
                  onClick={openChangeAccount}
                >
                  Change Account
                </StandardButton>
              </div>
            </>
        }
        <StandardButton
          block
          onClick={signOut}
        >
          Log Out
        </StandardButton>
      </div>
      <Modal
        open={isChangingAccount}
        onCancel={closeChangeAccount}
        title="Change Account"
        footer={null}
      >
        <AccountSelector
          onSelect={changeAccount}
        />
      </Modal>
    </>
  )

}