import { BulbOutlined, CoffeeOutlined, CommentOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { StandardButton } from "@src/components/standard/button/button";
import { StandardCard } from "@src/components/standard/card/card";
import { colors } from "@src/config/styles/variables";
import { UserContext } from "@src/context/user";
import { sendRequest } from "@src/utils/request/request.utils";
import { Button, Divider, Input, Space, message } from "antd";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ItemChangeUpdateType, MinimalNetworkTopicMap, TopicManagementDataTree, TopicManagementNetworkTree } from "../topics/topics";
import { NetworkTopics } from "../topics/topics.types";
import { Subject, SubjectActionType, SubjectGroup, SubjectGroupStatus, SubjectGroupTopic, SubjectInput, SubjectSession } from "./subjects.types";

interface SubjectItem {
  subject: Subject;
  subjectInput: SubjectInput;
}

const SubjectManagementPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
`

export const SubjectManagementPage = () => {

  return (
    <SubjectManagementPageContainer>
      <SubjectManagement />
    </SubjectManagementPageContainer>
  )

}

export const SubjectManagement = () => {

  const [user] = useContext(UserContext);

  const [hasActiveSession, setHasActiveSession] = useState<Boolean>(true);
  
  const [subjectSession, setSubjectSession] = useState<SubjectSession>();
  const [subjectGroups, setSubjectGroups] = useState<Array<SubjectGroup>>();
  const [subjects, setSubjects] = useState<Array<Subject>>();
  const [subjectInputs, setSubjectInputs] = useState<Array<SubjectInput>>();

  const [networkTopics, setNetworkTopics] = useState<NetworkTopics>();

  const loadNetworkMaps = async () => {
    const result: Array<NetworkTopics> = await sendRequest({
      method: 'get',
      url: '/network/topics/accounts',
      body: {
        accountIds: user?.accountId
      }
    });
    setNetworkTopics(result[0]);
  }

  useEffect(() => {
    if (user) {
      loadSubjectSession();
      loadNetworkMaps();
    }
  }, [user])
  
  useEffect(() => {
    if (subjectSession) {
      loadSubjectGroups();
    }
  }, [subjectSession])
  
  useEffect(() => {
    if (subjectGroups) {
      loadSubjects();
    }
  }, [subjectGroups])
  
  useEffect(() => {
    if (subjects) {
      loadSubjectInputs();
    }
  }, [subjects])

  const loadSubjectSession = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/subject/session/gathering',
      body: {
        accountId: user?.accountId
      }
    })
    if (!result) {
      setHasActiveSession(false);
      return;
    }
    setSubjectSession(result);
  }

  const loadSubjectGroups = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/subject/session/groups',
      body: {
        subjectSessionId: subjectSession?.id,
        status: SubjectGroupStatus.PENDING
      }
    })
    setSubjectGroups(result);
  }

  const loadSubjects = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/subject/session/subjects',
      body: {
        subjectSessionId: subjectSession?.id
      }
    })
    setSubjects(result);
  }
  
  const loadSubjectInputs = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/subject/session/inputs',
      body: {
        subjectSessionId: subjectSession?.id
      }
    })
    setSubjectInputs(result);
  }

  const handleConfirmSubjectGroup = (subjectGroup: SubjectGroup) => {
    if (!subjectGroups) {
      return;
    }
    const updatedSubjectGroups = [...subjectGroups].filter((group) => {
      return group.id !== subjectGroup.id
    })
    setSubjectGroups(updatedSubjectGroups);
  }

  if (!hasActiveSession) {
    return (
      <StandardCard>
        No Active Session For This Account
      </StandardCard>
    )
  }

  if (!subjects || !networkTopics || !subjectGroups || !subjectInputs || !subjectSession) {
    return (
      <StandardCard>
        Loading Connection Subjects...
      </StandardCard>
    )
  }

  const subjectsByGroup: any = {};
  subjects.forEach((subject: Subject) => {
    if (!subject.subjectGroupId) {
      return;
    }
    if (!subjectsByGroup[subject.subjectGroupId]) {
      subjectsByGroup[subject.subjectGroupId] = [];
    }
    subjectsByGroup[subject.subjectGroupId].push(subject);
  })

  const subjectInputsBySubject: any = {};
  subjects.forEach((subject: Subject) => {
    if (!subject.subjectInputId) {
      return;
    }
    const subjectInputMatch = subjectInputs.find((subjectInput: SubjectInput) => {
      return subject.subjectInputId === subjectInput.id;
    })
    subjectInputsBySubject[subject.id] = subjectInputMatch;
  })

  return (
    <>
      {
        subjectGroups.map((subjectGroup) => {
          const subjectItems: Array<SubjectItem> = subjectsByGroup[subjectGroup.id].map((subject: Subject) => {
            return {
              subject: subject,
              subjectInput: subjectInputsBySubject[subject.id]
            }
          })
          return (
            <SubjectGroupItem
              key={subjectGroup.id}
              subjectGroup={subjectGroup}
              subjectItems={subjectItems}
              subjectSession={subjectSession}
              networkTopics={networkTopics}
              onConfirmSubjectGroup={handleConfirmSubjectGroup}
            />
          )
        })
      }
    </>
  )

}

interface SubjectGroupItemProps {
  subjectGroup: SubjectGroup;
  subjectItems: Array<SubjectItem>;
  subjectSession: SubjectSession;
  networkTopics: NetworkTopics;
  onConfirmSubjectGroup: (subjectGroup: SubjectGroup) => void;
}

const SubjectGroupItemContainer = styled.div`
  display: flex;
  margin-top: 32px;

  .subject-group-item {
    width: 1000px;
  }

  .subject-group-title {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .subject-group-title-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  
    .subject-group-title-description {
      display: flex;
      flex-direction: row;
      justify-content: center;
      min-width: 500px;
      input {
        font-family: Quicksand, sans-serif;
        font-weight: 700;
        font-size: 16px;
        border-bottom-width: 1px !important;
        border-bottom-color: #CCC;
        border-bottom-style: solid;
        border-radius: 0;
      }
    }
  }

  .subject-group-inputs {

    .subject-group-input-title {
      font-weight: 600;
    }

    .subject-group-input {
      margin-top: 4px;
    }
  }

  .subject-group-submit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`

const SUBJECT_ACTION_EMOJIS = {
  [SubjectActionType.ASK]: <BulbOutlined />,
  [SubjectActionType.DISCUSS]: <CommentOutlined />,
  [SubjectActionType.ACTIVITY]: <ThunderboltOutlined />,
  [SubjectActionType.MEET]: <CoffeeOutlined />
}

export const SubjectGroupItem = ({subjectGroup, subjectItems, subjectSession, networkTopics, onConfirmSubjectGroup}: SubjectGroupItemProps) => {

  const [groupDescription, setGroupDescription] = useState(subjectGroup.description);

  const [updatedTopic, setUpdatedTopic] = useState<SubjectGroupTopic>({...subjectGroup.topic});
  const [selectedAccountTopic, setSelectedAccountTopic] = useState<SubjectGroupNetworkTopic>();

  const [addedCategoryValue, setAddedCategoryValue] = useState('');
  const [addedCategories, setAddedCategories] = useState<MinimalNetworkTopicMap>({});

  const updateAddedCategoryValue = (event: any) => {
    setAddedCategoryValue(event.target.value);
  }

  const addCategory = () => {
    let newTopics: MinimalNetworkTopicMap = {...addedCategories};
    if (selectedAccountTopic) {
      if (!newTopics[selectedAccountTopic.parent] || !newTopics[selectedAccountTopic.parent].topics) {
        newTopics[selectedAccountTopic.parent] = {
          topics: {}
        };
      }
      console.log(newTopics)
      // @ts-ignore
      newTopics[selectedAccountTopic.parent].topics[addedCategoryValue] = {};
    } else {
      newTopics[addedCategoryValue] = {};
    }
      
    setAddedCategories(newTopics);
    setAddedCategoryValue('');
  }

  const updateGroupDescription = (event: any) => {
    setGroupDescription(event.target.value);
  }

  const submitSubjectGroup = async () => {
    const result = await sendRequest({
      method: 'post',
      url: '/subject/group/confirm',
      body: {
        subjectGroupId: subjectGroup.id,
        description: groupDescription,
        topic: updatedTopic
      }
    })
    onConfirmSubjectGroup(result)
    message.success('Subject Topic Submitted!')
    return result;
  }

  let addPlaceholder = 'Add Parent Category';
  if (selectedAccountTopic) {
    addPlaceholder = `Add Child of "${selectedAccountTopic.parent}"`;
  }

  return (
    <SubjectGroupItemContainer>
      <StandardCard
        title={
          <>
            <div className="subject-group-title">
              <div className="subject-group-title-icon">
                {SUBJECT_ACTION_EMOJIS[subjectGroup.action]}
              </div>
              <div className="subject-group-title-description">
                <Input
                  bordered={false}
                  value={groupDescription}
                  onChange={updateGroupDescription}
                />
              </div>
            </div>
          </>
        }
      >
        <div
          className="subject-group-item"
        >
          <div className="subject-group-inputs">
            <div className="subject-group-input-title">
              Inputs
            </div>
            {
              subjectItems.map(({subject, subjectInput}) => {
                return (
                  <div
                    key={subject.id}
                    className="subject-group-input"
                  >
                    {subjectInput.text}
                  </div>
                )
              })
            }
          </div>
          <Divider />
          <div
            className="topic-item-add-category"
            style={{
              marginBottom: '16px'
            }}
          >
            <Space.Compact style={{ width: '400px' }}>
              <Input
                placeholder={addPlaceholder}
                value={addedCategoryValue}
                onChange={updateAddedCategoryValue}
              />
              <Button
                type="primary"
                onClick={addCategory}
              >
                Add
              </Button>
            </Space.Compact>
          </div>
          <div className="subject-group-topic-manager">
            <SubjectGroupTopicManager
              updatedTopic={updatedTopic}
              subjectGroup={subjectGroup}
              addedCategories={addedCategories}
              selectedAccountTopic={selectedAccountTopic}
              setSelectedAccountTopic={setSelectedAccountTopic}
              networkTopics={networkTopics}
              setUpdatedTopic={setUpdatedTopic}
            />
          </div>
          <Divider />
          <div className="subject-group-submit">
            <StandardButton
              type="primary"
              onClick={submitSubjectGroup}
            >
              Submit Subject
            </StandardButton>
          </div>
        </div>
      </StandardCard>
    </SubjectGroupItemContainer>
  )

}

interface TopicManagementItemTreeProps {
  updatedTopic: SubjectGroupTopic;
}

export const SubjectGroupTopicTree = ({updatedTopic}: TopicManagementItemTreeProps) => {

  if (!updatedTopic) {
    return (
      <TopicManagementDataTree>
        <div
          className="topic-tree-title"
        >
          New Topics
        </div>
        <div>
          No data
        </div>
      </TopicManagementDataTree>
    )
  }

  return (
    <TopicManagementDataTree>
      <div
        className="topic-tree-title"
      >
        New Topic
      </div>
      <div>
        <div
          style={{
            marginBottom: '8px'
          }}
        >
          Parent: <b>{updatedTopic.parent}</b>
        </div>
        <div
          style={{}}
        >
          Child: <b>{updatedTopic.child}</b>
        </div>
      </div>
    </TopicManagementDataTree>
  )

}

interface SubjectGroupNetworkTopic {
  parent: string;
  child?: string;
}

const getTopicFromKey = (key: string) => {
  let topic: SubjectGroupNetworkTopic = {
    parent: key
  }
  if (key.includes('__')) {
    const [parent, child] = key.split('__');
    topic = {
      parent: parent,
      child: child
    }
  }
  return topic;
}

const getItemChangeUpdateType = (selectedAccountTopic: SubjectGroupNetworkTopic) => {
  let updateType: ItemChangeUpdateType | undefined;
  if (!selectedAccountTopic.child) {
    updateType = ItemChangeUpdateType.MOVE_ONE;
  }
  else if (selectedAccountTopic.child) {
    updateType = ItemChangeUpdateType.CHANGE_ONE;
  }
  return updateType;
}

const SubjectGroupTopicManagerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

interface SubjectGroupTopicManagerProps {
  subjectGroup: SubjectGroup;
  addedCategories: MinimalNetworkTopicMap;
  updatedTopic: SubjectGroupTopic;
  networkTopics: NetworkTopics;
  selectedAccountTopic: SubjectGroupNetworkTopic | undefined;
  setSelectedAccountTopic: (topic: SubjectGroupNetworkTopic | undefined) => void;
  setUpdatedTopic: (topic: SubjectGroupTopic) => void;
}

export const SubjectGroupTopicManager = ({subjectGroup, addedCategories, updatedTopic, selectedAccountTopic, setSelectedAccountTopic, setUpdatedTopic, networkTopics}: SubjectGroupTopicManagerProps) => {

  const confirmTopicUpdate = () => {
    if (!selectedAccountTopic) {
      return;
    }
    const updateType = getItemChangeUpdateType(selectedAccountTopic);
    if (updateType === ItemChangeUpdateType.MOVE_ONE) {
      moveOneTopic(selectedAccountTopic);
    }
    if (updateType === ItemChangeUpdateType.CHANGE_ONE) {
      changeOneTopic(selectedAccountTopic);
    }
  }

  const moveOneTopic = (newTopic: SubjectGroupNetworkTopic) => {
    const newTopicData = {...updatedTopic};
    newTopicData.parent = newTopic.parent;
    if (newTopic.child) {
      newTopicData.child = newTopic.child;
    }
    setUpdatedTopic(newTopicData)
  }
  
  const changeOneTopic = (newTopic: SubjectGroupNetworkTopic) => {
    const newTopicData = {...updatedTopic};
    newTopicData.parent = newTopic.parent;
    if (newTopic.child) {
      newTopicData.child = newTopic.child;
    }
    setUpdatedTopic(newTopicData)
  }

  const selectNetworkTopic = (key: string) => {
    if (!key) {
      setSelectedAccountTopic(undefined);
      return;
    }
    const topic = getTopicFromKey(key);
    setSelectedAccountTopic(topic);
  }

  return (
    <SubjectGroupTopicManagerContainer>
      <TopicManagementNetworkTree
        networkTopics={networkTopics}
        addedCategories={addedCategories}
        onSelectNetworkTopic={selectNetworkTopic}
      />
      <SubjectGroupTopicTree
        updatedTopic={updatedTopic}
      />
      <SubjectGroupTopicChangeStatus
        selectedAccountTopic={selectedAccountTopic}
        updatedTopic={updatedTopic}
        onConfirmUpdate={confirmTopicUpdate}
      />
    </SubjectGroupTopicManagerContainer>
  )

}

const SubjectGroupChangeStatusContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  .change-status-text {
    color: ${colors.darkGray};
    text-align: center;
  }

  .topic-status-topic {
    color: ${colors.primary}
  }

  .change-status-confirm {
    margin-top: 32px;
  }
`

interface SubjectGroupTopicChangeStatusProps {
  selectedAccountTopic?: SubjectGroupNetworkTopic;
  updatedTopic?: SubjectGroupTopic;
  onConfirmUpdate: () => void;
}

export const SubjectGroupTopicChangeStatus = ({selectedAccountTopic, updatedTopic, onConfirmUpdate}: SubjectGroupTopicChangeStatusProps) => {

  const isUpdateReady = selectedAccountTopic;

  let updateType: ItemChangeUpdateType | undefined;
  if (isUpdateReady) {
    updateType = getItemChangeUpdateType(selectedAccountTopic);
  }

  let updateStatusMessage = (
    <div>
      Choose/create an account topic and a new topic to update
    </div>
  );
  if (updateType) {
    switch(updateType) {
      case ItemChangeUpdateType.MOVE_ONE:
        updateStatusMessage = (
          <div>
            Move<br /><span className="topic-status-topic">{updatedTopic?.parent} -&gt; {updatedTopic?.child}</span><br />topic to<br /><span className="topic-status-topic">{selectedAccountTopic?.parent} -&gt; {updatedTopic?.child}</span>
          </div>
        )
        break;
      case ItemChangeUpdateType.CHANGE_ONE:
        updateStatusMessage = (
          <div>
            Change<br /><span className="topic-status-topic">{updatedTopic?.parent} -&gt; {updatedTopic?.child}</span><br />topic to<br /><span className="topic-status-topic">{selectedAccountTopic?.parent} -&gt; {selectedAccountTopic?.child}</span>
          </div>
        )
        break;
    }
  }

  return (
    <SubjectGroupChangeStatusContainer>
      <div
        className="change-status-text"
      >
        {updateStatusMessage}
      </div>
      {
        isUpdateReady &&
          <div
            className="change-status-confirm"
          >
            <StandardButton
              size="small"
              type="primary"
              onClick={onConfirmUpdate}
            >
              Confirm Update
            </StandardButton>
          </div>
      }
    </SubjectGroupChangeStatusContainer>
  )

}
