import { paragon } from '@useparagon/connect';
import { IntegrationPlatformItem, IntegrationPlatformProps } from "../integrations";
import { message } from 'antd';



export const IntegrationTrello = (props: IntegrationPlatformProps) => {

  const connectToTrello = async () => {
    paragon.connect('trello', {
      onSuccess: async () => {
        if (props.onConnect) {
          await props.onConnect('trello');
          message.success('Connected to Trello!');
        }
      },
      onError: (error) => {}
    });
  }

  return (
    <IntegrationPlatformItem
      name="trello"
      label="Trello"
      isEnabled={props.paragonUser?.integrations['trello']?.enabled || false}
      connect={connectToTrello}
    />
  )
}