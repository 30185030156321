import styled from "styled-components";
import { Editor, Element, Transforms } from "slate";
import { useSelected } from "slate-react";
import { Tag } from "antd";
import { ConfigElementProps, SlateParamElement } from "@src/components/config/config-editor.types"
import { ConfigParam } from "@src/components/config/config.types";
import { colors } from "@src/config/styles/variables";

export const withSingleLine = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
}

export const withParams = (editor: Editor) => {
  const { isVoid, isInline, insertNode } = editor;

  editor.isInline = (element: Element) => {
    return element.type === 'param' ? true : isInline(element);
  }

  editor.isVoid = (element: Element) => {
    return element.type === 'param' ? true : isVoid(element)
  }

  editor.insertParam = (param: ConfigParam) => {
    insertNode({
      type: 'param',
      param: param,
      children: [{text: ''}],
    })
  }

  editor.removeParam = (param: ConfigParam) => {
    removeParam(editor, param);
  }

  return editor;
}

const removeParam = (editor: Editor, param: ConfigParam) => {
  Transforms.removeNodes(editor, {
    voids: true,
    match: (node) => {
      const paramNode = node as SlateParamElement;
      if (paramNode.type && paramNode.type === 'param') {
        return param.key === paramNode.param.key;
      }
      return false;
    }
  })
}

export const ConfigTag = styled(Tag)`
  margin-right: 0;
  cursor: pointer;

  .ant-tag-content {
    user-select: none;
  }

  .ant-tag-close-icon {
    margin-inline-start: 6px !important;
  }
`

const ParamComponent = ({ attributes, children, element, editor }: ConfigElementProps) => {

  const selected = useSelected();

  const paramElement = element as SlateParamElement;

  const color = selected ? colors.blue : colors.primary;

  const remove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    editor.removeParam(paramElement.param);
  };

  return (
    <span {...attributes} contentEditable={false}>
      <InlineChromiumBugfix />
      <ConfigTag
        color={color}
        closable
        onClose={remove}
      >
        <span
          className="ant-tag-content"
        >
          {paramElement.param.display}
        </span>
      </ConfigTag>
      <div
        style={{
          display: 'none'
        }}
      >
        {children}
      </div>
      <InlineChromiumBugfix />
    </span>
  )
}

export const ConfigElement = (props: ConfigElementProps) => {
  const { attributes, children, element } = props

  switch (element.type) {
    case 'param':
      return <ParamComponent {...props} />
    default:
      return <div {...attributes}>{children}</div>
  }
}

export const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0
    }}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)
