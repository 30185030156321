import { fonts } from "@src/config/styles/variables";
import { Input, InputProps } from "antd";
import { TextAreaProps } from "antd/es/input";
import styled from "styled-components";

export const StandardInput = styled(Input)<InputProps>`
  padding: 12px;
  font-family: ${fonts.secondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  &.ant-input-sm {
    padding: 8px;
    font-size: 14px;
    line-height: 1.25;
  }
`

export const StandardTextArea = styled(Input.TextArea)<TextAreaProps>`
  padding: 8px;
  font-family: ${fonts.secondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
`