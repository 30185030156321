import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components/macro"
import classNames from "classnames";
import { Avatar, Image } from "antd";
import { useSpring, animated } from '@react-spring/web'

import { colors } from "@src/config/styles/variables";
import { HiveConnectionsMessage, HiveMessage, HiveTextMessage } from "@src/types/hive/chat.types";
import { HiveChatLoading } from "./hive-chat-loading";

import beeFace from '@src/assets/images/bee-face.png';
import { HiveChatConnectionsMessage } from "./hive-chat-connections";
import { debounce } from "@src/utils/debounce/debounce.utils";
import { UserOutlined } from "@ant-design/icons";

const HiveChatMessagesContainer = styled.div`
  flex: 1;
  background-color: #FFF;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 24px;
`

interface HiveChatMessagesProps {
  messages: Array<HiveMessage>;
  isGenerating: boolean;
  addMessage: (message: HiveMessage) => void;
}

export const HiveChatMessages = ({messages, isGenerating, addMessage}: HiveChatMessagesProps) => {

  const [showLoading, setShowLoading] = useState(false);
  const [minContainerHeight, setMinContainerHeight] = useState(0);

  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (!showLoading && isGenerating) {
      setShowLoading(true);
    }
  }, [isGenerating])

  const getContainerHeight = () => containerRef.current.offsetHeight;

  useEffect(() => {
    if (!containerRef.current) return;
    const scrollToBottom = debounce(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 100)
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current.offsetHeight > minContainerHeight) {
        setMinContainerHeight(getContainerHeight())
        scrollToBottom();
      }
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <HiveChatMessagesContainer
      ref={containerRef}
      style={{
        minHeight: minContainerHeight
      }}
    >
      <div>
        {
          messages.map((message) => {
            return (
              <HiveChatMessage
                message={message}
                addMessage={addMessage}
              />
            )
          })
        }
      </div>
      {
        showLoading &&
          <HiveChatLoading
            isComplete={!isGenerating}
            onFinish={() => setShowLoading(false)}
          />
      }
    </HiveChatMessagesContainer>
  )
}

const HiveChatMessageContainer = styled.div`
  width: 100%;
  margin: 8px 0;
  position: relative;

  &:first-child {
    margin-top: 0;
  }
`

interface HiveChatMessageProps {
  message: HiveMessage;
  addMessage: (message: HiveMessage) => void;
}

export const HiveChatMessage = ({message, addMessage}: HiveChatMessageProps) => {

  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [height, setHeight] = useState<number>();

  const [textMessageStyles, api] = useSpring(
    () => ({
      from: {
        opacity: 0,
        height: 'auto',
        padding: '0 0',
        y: -10
      },
      config: {
        tension: 100,
        friction: 10
      }
    }),
    []
  )

  useLayoutEffect(() => {
    if (!height && containerRef.current && containerRef.current.offsetHeight > 0) {
      setHeight(containerRef.current.offsetHeight);
    }
    api.set({height: '0'})
  }, []);


  useEffect(() => {
    if (height) {
      api.start({
        height: `${height + 24}px`,
        padding: '8px 0',
        y: 0
      })
      api.start({
        opacity: 1,
        config: {
          clamp: true
        }
      })
    }
  }, [height])

  const isHiveTextMessage = (message: HiveMessage): message is HiveTextMessage => {
    return (message as HiveTextMessage).text !== undefined;
  }
  
  const isHiveConnectionsMessage = (message: HiveMessage): message is HiveConnectionsMessage => {
    return (message as HiveConnectionsMessage).connections !== undefined;
  }
  

  return (
    <HiveChatMessageContainer
      ref={containerRef}
    >
      {
        isHiveTextMessage(message) &&
          <animated.div style={textMessageStyles}>
            <HiveChatTextMessage
              message={message}
            />
          </animated.div>
      }
      {
        isHiveConnectionsMessage(message) &&
          <HiveChatConnectionsMessage
            message={message}
            addMessage={addMessage}
          />
      }
    </HiveChatMessageContainer>
  )
}

const HiveChatTextMessageContainer = styled.div`

  .chat-message-container {
    display: flex;
    align-content: center;
    flex-direction: row;
    gap: 8px;
    height: 100%;

    .chat-avatar {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      
      .avatar-display {
        width: 100%;
        display: flex;
        justify-content: center;

        .ant-avatar {
          background: ${colors.mediumGray};
        }

        .anticon {
          position: relative;
          top: -1px;
        }
      }
    }

    .chat-message {
      max-width: 700px;
      
      .chat-message-content {
        border-radius: 10px;
        border-bottom-left-radius: 0;
        padding: 16px;
        background-color: ${colors.primary};
        font-size: 16px;
        color: #fff;

        a {
          color: #F1F0F0;
          text-decoration: underline;
        }
      }
    }

    &.user-message {
      flex-direction: row-reverse;;

      .chat-message-content {
        color: rgba(0, 0, 0, .85);
        background-color: rgba(28, 116, 140, .1);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 0;
      }
    }

  }
`

interface HiveChatTextMessageProps {
  message: HiveTextMessage;
}

export const HiveChatTextMessage = ({message}: HiveChatTextMessageProps) => {

  const containerClasses = classNames({
    'chat-message-container': true,
    'user-message': message.isUser
  })

  let avatarImage =
    <Image
      src={beeFace}
      preview={false}
      width={32}
    />;
  if (message.isUser) {
    avatarImage = <UserOutlined size={32} />;
  }

  return (
    <HiveChatTextMessageContainer>
      <div
        className={containerClasses}
      >
        <div
          className="chat-avatar"
        >
          <div
            className="avatar-display"
          >
            <Avatar
              size={32}
              src={avatarImage}
            />
          </div>
        </div>
        <div
          className="chat-message"
        >
          <div
            className="chat-message-content"
          >
            {message.text}
          </div>
        </div>
      </div>
    </HiveChatTextMessageContainer>
  )

}
