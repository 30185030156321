import { StandardButton } from "@src/components/standard/button/button";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ImportConfigEditor } from "./import-config/import-config-editor";
import { sendRequest } from "@src/utils/request/request.utils";
import { UserContext } from "@src/context/user";
import { Divider, List } from "antd";
import { ImportConfig } from "./import-config/import-config.types";

const ImportConfigsPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`
const ImportConfigsContainer = styled.div`
  flex: 1;
  background-color: #FFF;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 24px;
`

const EditConfigsContainer = styled.div`
  background-color: #FFF;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 48px;
`


export const ImportConfigsPage = () => {

  const [user] = useContext(UserContext);

  const [isEditingConfig, setIsEditingConfig] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<ImportConfig>();

  useEffect(() => {
    if (!isEditingConfig && selectedConfig) {
      setIsEditingConfig(true);
    }
  }, [selectedConfig])

  const [configs, setConfigs] = useState<Array<ImportConfig>>([]);

  const createConfig = () => {
    setIsEditingConfig(true);
  }

  useEffect(() => {
    if (user) {
      fetchConfigs();
    }
  }, [user])

  const fetchConfigs = async () => {
    const response = await sendRequest({
      method: 'get',
      url: '/importer/config/list',
      body: {
        accountId: user?.accountId
      }
    })
    setConfigs(response);
  }

  return (
    <ImportConfigsPageContainer>
      {
        isEditingConfig &&
          <EditConfigsContainer>
            <ImportConfigEditor
              config={selectedConfig}
              setConfig={setSelectedConfig}
            />
          </EditConfigsContainer>
      }
      {
        !isEditingConfig &&
          <ImportConfigsContainer>
            <StandardButton
              onClick={createConfig}
            >
              Create New Import Config
            </StandardButton>
            <Divider />
            <List
              dataSource={configs}
              rowKey="id"
              renderItem={(config: ImportConfig) => {
                return (
                  <List.Item
                    actions={[
                      <StandardButton
                        onClick={() => setSelectedConfig(config)}
                      >
                        Edit Config
                      </StandardButton>
                    ]}
                  >
                    <List.Item.Meta
                      title={`${config.name} (id: ${config.id})`}
                    />
                  </List.Item>
                )
              }}
            />
          </ImportConfigsContainer>
      }
    </ImportConfigsPageContainer>
  )

}