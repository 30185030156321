import { Account } from "@src/types/account.types";
import { sendRequest } from "@src/utils/request/request.utils";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { StandardButton } from "../standard/button/button";
import { StandardSelect } from "../standard/select/select";

const AccountSelectorContainer = styled.div`
  .select-account-actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
`

export interface AccountSelectItem {
  label: string;
  value: string;
}

interface AccountSelectorProps {
  onSelect: (account: AccountSelectItem) => void;
  skipConfirm?: boolean;
}

export const AccountSelector = ({onSelect, skipConfirm}: AccountSelectorProps) => {

  const [accounts, setAccounts] = useState<Array<AccountSelectItem>>();
  const [selectedAccount, setSelectedAccount] = useState<AccountSelectItem>();

  useEffect(() => {
    getAccounts();
  }, [])

  const getAccounts = async () => {
    const response = await sendRequest({
      method: 'get',
      url: '/account/list'
    })
    const accountList: Array<AccountSelectItem> = response.map((account: Account) => {
      return {
        value: account.id,
        label: account.name
      }
    })
    setAccounts(accountList);
  }

  const selectAccount = async (_value: any, account: any) => {
    setSelectedAccount(account);
    if (skipConfirm) {
      await onSelect(account);
    }
  }

  const confirmAccount = async () => {
    if (!selectedAccount) {
      return;
    }
    await onSelect(selectedAccount);
  }

  return (
    <AccountSelectorContainer>
      {
        accounts &&
          <>
            <StandardSelect
              options={accounts}
              onChange={selectAccount}
              placeholder="Select an Account"
            />
            <div
              className="select-account-actions"
            >
              {
                selectedAccount && !skipConfirm &&
                  <StandardButton
                    size="small"
                    onClick={confirmAccount}
                  >
                    Confirm
                  </StandardButton>
              }
            </div>
          </>
      }
    </AccountSelectorContainer>
  )

}