import { StandardButton } from "@src/components/standard/button/button";
import { isNumber } from "@src/utils/number/number.utils";
import { List, Typography } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ConfigInput } from "./import-config-input";
import { ImportConfigFields, ImportConfigHandler } from "./import-config.types";

const ImportConfigHandlersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

interface ImportConfigHandlersProps {
  handlers: Array<ImportConfigHandler>;
  fields: ImportConfigFields;
  addHandler: () => void;
  saveHandler: (index: number, handler: ImportConfigHandler) => void;
}

export const ImportConfigHandlers = (props: ImportConfigHandlersProps) => {

  const [editingHandlerIndex, setEditingHandlerIndex] = useState<number | undefined>();
  const [nextEditingIndex, setNextEditingIndex] = useState<number | undefined>();

  useEffect(() => {
    if (nextEditingIndex !== undefined) {
      setEditingHandlerIndex(nextEditingIndex);
      setNextEditingIndex(undefined);
    }
  }, [nextEditingIndex])

  const {handlers, addHandler, fields, saveHandler} = props;

  const editHandler = (index: number) => {
    setEditingHandlerIndex(undefined);
    setNextEditingIndex(index);
  }

  const saveCurrentHandler = (index: number, handler: ImportConfigHandler) => {
    saveHandler(index, handler);
    setEditingHandlerIndex(undefined);
  }

  return (
    <ImportConfigHandlersContainer>
      {
        editingHandlerIndex !== undefined && isNumber(editingHandlerIndex) &&
          <ImportConfigHandlerEdit
            handlers={handlers}
            fields={fields}
            selectedIndex={editingHandlerIndex}
            saveHandler={saveCurrentHandler}
          />
      }
      <ImportConfigHandlersDisplay
        handlers={handlers}
        fields={fields}
        selectedIndex={editingHandlerIndex}
        editHandler={editHandler}
      />
      <StandardButton
        onClick={addHandler}
      >
        Add Handler
      </StandardButton>
    </ImportConfigHandlersContainer>
  )

}

const ImportConfigHandlersDisplayContainer = styled.div``

interface ImportConfigHandlersDisplayProps {
  handlers: Array<ImportConfigHandler>;
  fields: ImportConfigFields;
  selectedIndex: number | undefined;
  editHandler: (index: number) => void;
}

export const ImportConfigHandlersDisplay = (props: ImportConfigHandlersDisplayProps) => {

  const {handlers, editHandler, selectedIndex} = props;

  return (
    <ImportConfigHandlersDisplayContainer>
      <List
        header={
          <Typography.Text
            strong
          >
            Handlers
          </Typography.Text>
        }
        bordered
        dataSource={handlers}
        renderItem={(handler: ImportConfigHandler, index) => {
          let listItemStyle: any = {};
          if (selectedIndex === index) {
            listItemStyle = {
              fontWeight: 'bold'
            }
          }
          return (
            <List.Item
              key={`${handler.type}-${index}`}
              style={listItemStyle}
              actions={[
                <a
                  onClick={() => editHandler(index)}
                >
                  Edit
                </a>
              ]}
            >
              <i>{handler.type}</i> - {handler.template?.substring(0, 60)}
            </List.Item>
          )
        }}
      />
    </ImportConfigHandlersDisplayContainer>
  )

}

const ImportConfigHandlerEditContainer = styled.div``

interface ImportConfigHandlerEditProps {
  handlers: Array<ImportConfigHandler>;
  fields: ImportConfigFields;
  selectedIndex: number;
  saveHandler: (index: number, handler: ImportConfigHandler) => void;
}

export const ImportConfigHandlerEdit = (props: ImportConfigHandlerEditProps) => {

  const {handlers, selectedIndex, fields, saveHandler} = props;

  const handler = handlers[selectedIndex];

  return (
    <ImportConfigHandlerEditContainer>
      <ConfigInput
        handler={handler}
        fields={fields}
        index={selectedIndex}
        saveHandler={saveHandler}
      />
    </ImportConfigHandlerEditContainer>
  )

}
