import { StandardButton } from '@src/components/standard/button/button';
import { Card, Image, List } from 'antd';
import { AuthenticatedConnectUser, ConnectUser, SDK_EVENT, paragon } from '@useparagon/connect';
import styled from 'styled-components';
import { IntegrationGoogleDrive } from './platforms/drive';
import { IntegrationNotion } from './platforms/notion';
import { sendRequest } from '@src/utils/request/request.utils';
import { IntegrationConfluence } from './platforms/confluence';
import { IntegrationJira } from './platforms/jira';
import { IntegrationTrello } from './platforms/trello';

import slackLogo from '@src/assets/images/integrations/slack.png';
import driveLogo from '@src/assets/images/integrations/googledrive.png';
import notionLogo from '@src/assets/images/integrations/notion.png';
import confluenceLogo from '@src/assets/images/integrations/confluence.png';
import jiraLogo from '@src/assets/images/integrations/jira.png';
import airtableLogo from '@src/assets/images/integrations/airtable.png';
import trelloLogo from '@src/assets/images/integrations/trello.png';
import sharepointLogo from '@src/assets/images/integrations/sharepoint.png';
import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '@src/context/user';
import { LoadingScreen } from '@src/components/loading/loading';
import { colors } from '@src/config/styles/variables';
import { LoadingOutlined } from '@ant-design/icons';
import { StandardCard } from '@src/components/standard/card/card';
import { IntegrationSlack } from './platforms/slack';
import { IntegrationSharepoint } from './platforms/sharepoint';
import { IntegrationAirtable } from './platforms/airtable';

const logoMap: any = {
  slack: slackLogo,
  googledrive: driveLogo,
  notion: notionLogo,
  confluence: confluenceLogo,
  jira: jiraLogo,
  airtable: airtableLogo,
  trello: trelloLogo,
  sharepoint: sharepointLogo,
}

const IntegrationsPageContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .integrations-list {
    width: 600px;
  }
`

export const IntegrationsPage = () => {

  const [user] = useContext(UserContext);

  const [paragonError, setParagonError] = useState<string>();
  const [paragonUser, setParagonUser] = useState<AuthenticatedConnectUser>();

  useEffect(() => {
    if (user && !paragonUser) {
      authParagonUser();
    }
  }, [user])

  const onConnect = async (name: string) => {
    console.log(name);
  }

  const authParagonUser = async () => {
    try {
      const {token} = await sendRequest({
        method: 'post',
        url: '/integrations/paragon/token'
      })
      const projectId: any = process.env.REACT_APP_PARAGON_PROJECT_ID;
      await paragon.authenticate(
        projectId,
        token
      )
      const result = await paragon.getUser() as AuthenticatedConnectUser;
      setParagonUser(result);
    } catch (err: any) {
      setParagonError(err.message);
    }

    paragon.subscribe(
      SDK_EVENT.ON_INTEGRATION_INSTALL,
      (_event: any, user: AuthenticatedConnectUser) => {
        setParagonUser(user);
      }
    );
    
    paragon.subscribe(
      SDK_EVENT.ON_INTEGRATION_UNINSTALL,
      (_event: any, user: AuthenticatedConnectUser) => {
        setParagonUser(user);
      }
    );
  }

  const integrations = useMemo(() => [
    <IntegrationSlack onConnect={onConnect} />,
    <IntegrationGoogleDrive onConnect={onConnect} paragonUser={paragonUser} />,
    <IntegrationNotion onConnect={onConnect} paragonUser={paragonUser} />,
    <IntegrationConfluence onConnect={onConnect} paragonUser={paragonUser} />,
    <IntegrationJira onConnect={onConnect} paragonUser={paragonUser} />,
    <IntegrationSharepoint onConnect={onConnect} paragonUser={paragonUser} />,
    <IntegrationAirtable onConnect={onConnect} paragonUser={paragonUser} />,
    <IntegrationTrello onConnect={onConnect} paragonUser={paragonUser} />
  ], [paragonUser])

  return (
    <IntegrationsPageContainer>
      {
        !paragonUser &&
          <Card>
            {
              paragonError ?
                <>
                  {paragonError}
                </>
              :
                <>
                  <LoadingOutlined />{'  '}Loading Integrations...
                </>
            }
          </Card>
      }
      {
        paragonUser &&
          <StandardCard>
            <List
              className="integrations-list"
              dataSource={integrations}
              renderItem={(item) => {
                return (
                  <List.Item>
                    {item}
                  </List.Item>
                )
              }}
            />
          </StandardCard>
      }
    </IntegrationsPageContainer>
  )

}

const IntegrationPlatformContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .integration-platform-logo {
    width: 50px;
    display: flex;
    justify-content: center;

    .ant-image {
      height: 32px;
      width: 32px;
    }
  }

  .integration-platform-title {
    min-width: 200px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    font-size: 18px;

    &.enabled {
      font-weight: bold;
    }
  }
  
  .integration-platform-connect {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    .ant-btn {
      min-width: 250px;
    }

  }
`

interface IntegrationPlatformItemProps {
  name: string;
  label: string;
  isEnabled: boolean;
  connect: () => Promise<void>;
}

export const IntegrationPlatformItem = (props: IntegrationPlatformItemProps) => {

  const handleConnect = async () => {
    await props.connect();
  }

  return (
    <IntegrationPlatformContainer key={props.name}>
      <div className="integration-platform-logo">
        <Image preview={false} src={logoMap[props.name]} />
      </div>
      <div
        className={
          `integration-platform-title ${props.isEnabled ? 'enabled' : ''}`
        }>
        {props.label}
      </div>
      <div className="integration-platform-connect">
        <StandardButton
          onClick={handleConnect}
          type={props.isEnabled ? 'primary' : 'default'}
          ghost={props.isEnabled}
        >
          {
            props.isEnabled ?
              <>Manage {props.label}</>
            :
              <>Connect to {props.label}</>
          }
        </StandardButton>
      </div>
    </IntegrationPlatformContainer>
  )
}

export interface IntegrationPlatformProps {
  onConnect: (name: string) => Promise<void>;
  paragonUser?: AuthenticatedConnectUser;
}
