import { paragon } from '@useparagon/connect';
import { IntegrationPlatformItem, IntegrationPlatformProps } from "../integrations";
import { message } from 'antd';



export const IntegrationConfluence = (props: IntegrationPlatformProps) => {

  const connectToConfluence = async () => {
    paragon.connect('confluence', {
      onSuccess: async () => {
        if (props.onConnect) {
          await props.onConnect('confluence');
          message.success('Connected to Confluence!');
        }
      },
      onError: (error) => {}
    });
  }

  return (
    <IntegrationPlatformItem
      name="confluence"
      label="Confluence"
      isEnabled={props.paragonUser?.integrations['confluence']?.enabled || false}
      connect={connectToConfluence}
    />
  )
}