import styled from "styled-components"

import logo from '@src/assets/images/pairup-logo-horizontal.png';

const LoadingScreenContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 200px;
    max-width: 100%;

    img {
      max-width: 100%;
    }
  }
`

export const LoadingScreen = () => {

  return (
    <LoadingScreenContainer>
      <div>
        <img
          src={logo}
          alt="PairUp Logo"
        />
      </div>
    </LoadingScreenContainer>
  )

}
