import { StandardButton } from "@src/components/standard/button/button";
import { StandardCard } from "@src/components/standard/card/card";
import { colors } from "@src/config/styles/variables";
import { UserContext } from "@src/context/user";
import { sendRequest } from "@src/utils/request/request.utils";
import { Button, Divider, Input, Space, Tree, message } from 'antd';
import type { DataNode } from 'antd/es/tree';
import Paragraph from "antd/es/typography/Paragraph";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AccountNetworkTopicsMap, NetworkTopicItem, NetworkTopics } from "./topics.types";


const TopicManagementPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
`

export const TopicManagementPage = () => {

  return (
    <TopicManagementPageContainer>
      <TopicManagement />
    </TopicManagementPageContainer>
  )

}

export const TopicManagement = () => {

  const [user] = useContext(UserContext);

  const [networkTopicsMap, setNetworkTopicsMap] = useState<AccountNetworkTopicsMap>();
  const [topicItems, setTopicItems] = useState<Array<NetworkTopicItem>>();

  useEffect(() => {
    if (user) {
      loadTopicItems();
    }
  }, [user])
  
  useEffect(() => {
    loadNetworkMaps();
  }, [topicItems]);

  const loadTopicItems = async () => {
    const result: Array<NetworkTopicItem> = await sendRequest({
      method: 'get',
      url: '/network/items/unconfirmed',
      body: {
        accountId: user?.accountId
      }
    });
    setTopicItems(result);
  }

  const loadNetworkMaps = async () => {
    if (!topicItems) {
      return;
    }
    const accountIds: Array<string> = [];
    topicItems.forEach((topicItem) => {
      if (!accountIds.includes(topicItem.accountId)) {
        accountIds.push(topicItem.accountId);
      }
    })
    const result: Array<NetworkTopics> = await sendRequest({
      method: 'get',
      url: '/network/topics/accounts',
      body: {
        accountIds: accountIds.join(',')
      }
    });
    const accountTopicsMap: AccountNetworkTopicsMap = {};
    result.forEach((networkTopics) => {
      accountTopicsMap[networkTopics.accountId] = networkTopics;
    })
    setNetworkTopicsMap(accountTopicsMap);
  }

  if (!topicItems || !networkTopicsMap) {
    return (
      <StandardCard>
        Loading Topic Items...
      </StandardCard>
    )
  }

  return (
    <>
      {
        topicItems.map((topicItem) => {
          return (
            <TopicManagementItem
              key={topicItem.id}
              topicItem={topicItem}
              networkTopics={networkTopicsMap[topicItem.accountId]}
            />
          )
        })
      }
    </>
  )

}

export enum ItemChangeUpdateType {
  MOVE_ALL = 'move_all',
  MOVE_ONE = 'move_one',
  CHANGE_ONE = 'change_one'
}

const getItemChangeUpdateType = (selectedAccountTopic: SelectedTopic, selectedNewTopic: SelectedTopic) => {
  let updateType: ItemChangeUpdateType | undefined;
  if (!selectedNewTopic.child) {
    updateType = ItemChangeUpdateType.MOVE_ALL;
  }
  else if (!selectedAccountTopic.child) {
    updateType = ItemChangeUpdateType.MOVE_ONE;
  }
  else if (selectedAccountTopic.child) {
    updateType = ItemChangeUpdateType.CHANGE_ONE;
  }
  return updateType;
}

const getTopicFromKey = (key: string) => {
  let topic: SelectedTopic = {
    parent: key
  }
  if (key.includes('__')) {
    const [parent, child] = key.split('__');
    topic = {
      parent: parent,
      child: child
    }
  }
  return topic;
}

interface TopicManagementItemProps {
  topicItem: NetworkTopicItem;
  networkTopics: NetworkTopics;
}

const TopicManagementItemContainer = styled.div`
  display: flex;
  margin-top: 32px;

  .topic-item {
    width: 1000px;
  }

  .topic-item-summaries {
    margin-bottom: 16px;

    pre {
      margin-top: 0;
    }
  }

  .topic-item-add-category {
    margin-bottom: 16px;
  }

  .topic-item-trees {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
`

interface SelectedTopic {
  parent: string;
  child?: string;
}

export interface MinimalNetworkTopicMap {
  [topicId: string]: {
    topics?: MinimalNetworkTopicMap
  }
}

export const TopicManagementItem = ({topicItem, networkTopics}: TopicManagementItemProps) => {

  const [updatedTopicItem, setUpdatedTopicItem] = useState<NetworkTopicItem>({...topicItem});

  const [selectedAccountTopic, setSelectedNetworkTopic] = useState<SelectedTopic>();
  const [selectedNewTopic, setSelectedItemTopic] = useState<SelectedTopic>();

  const [addedCategoryValue, setAddedCategoryValue] = useState('');
  const [addedCategories, setAddedCategories] = useState<MinimalNetworkTopicMap>({});

  const [areSummariesExpanded, setAreSummariesExpanded] = useState(false);

  const updateAddedCategoryValue = (event: any) => {
    setAddedCategoryValue(event.target.value);
  }

  const addCategory = () => {
    let newTopics: MinimalNetworkTopicMap = {...addedCategories};
    if (selectedAccountTopic) {
      if (!newTopics[selectedAccountTopic.parent] || !newTopics[selectedAccountTopic.parent].topics) {
        newTopics[selectedAccountTopic.parent] = {
          topics: {}
        };
      }
      console.log(newTopics)
      // @ts-ignore
      newTopics[selectedAccountTopic.parent].topics[addedCategoryValue] = {};
    } else {
      newTopics[addedCategoryValue] = {};
    }
      
    setAddedCategories(newTopics);
    setAddedCategoryValue('');
  }

  const selectNetworkTopic = (key: string) => {
    if (!key) {
      setSelectedNetworkTopic(undefined);
      return;
    }
    const topic = getTopicFromKey(key);
    setSelectedNetworkTopic(topic);
  }

  const selectItemTopic = (key: string) => {
    if (!key) {
      setSelectedItemTopic(undefined);
      return;
    }
    const topic = getTopicFromKey(key);
    setSelectedItemTopic(topic);
  }

  const confirmTopicUpdate = () => {
    if (!selectedAccountTopic || !selectedNewTopic) {
      return;
    }
    const updateType = getItemChangeUpdateType(selectedAccountTopic, selectedNewTopic);
    if (updateType === ItemChangeUpdateType.MOVE_ALL) {
      moveAllTopics(selectedAccountTopic.parent, selectedNewTopic.parent);
    }
    if (updateType === ItemChangeUpdateType.MOVE_ONE) {
      moveOneTopic(selectedAccountTopic, selectedNewTopic);
    }
    if (updateType === ItemChangeUpdateType.CHANGE_ONE) {
      changeOneTopic(selectedAccountTopic, selectedNewTopic);
    }
    setSelectedItemTopic(undefined);
  }

  const moveAllTopics = (newParent: string, oldParent: string) => {
    const newTopicItemData = {...updatedTopicItem}.data;
    if (!newTopicItemData) {
      return;
    }
    if (!newTopicItemData[newParent]) {
      newTopicItemData[newParent] = {...newTopicItemData[oldParent]};
    } else {
      if (!newTopicItemData[newParent].topics) {
        newTopicItemData[newParent].topics = {};
      }
      // @ts-ignore
      Object.entries(newTopicItemData[oldParent].topics).forEach(([childTopicKey, childTopicData]) => {
        // @ts-ignore
        newTopicItemData[newParent].topics[childTopicKey] = {...childTopicData};
      })
    }
    delete newTopicItemData[oldParent];
    const newTopicItem = {
      ...updatedTopicItem,
      data: newTopicItemData
    }
    setUpdatedTopicItem(newTopicItem)
  }

  const moveOneTopic = (newTopic: SelectedTopic, oldTopic: SelectedTopic) => {
    const newTopicItemData: any = {...updatedTopicItem}.data;
    if (!newTopicItemData || !oldTopic.child) {
      return;
    }
    if (!newTopicItemData[newTopic.parent]) {
      newTopicItemData[newTopic.parent] = {
        experience: 'unknown',
        topics: {}
      }
    }
    newTopicItemData[newTopic.parent].topics[oldTopic.child] = {...newTopicItemData[oldTopic.parent].topics[oldTopic.child]}
    delete newTopicItemData[oldTopic.parent].topics[oldTopic.child];
    if (Object.keys(newTopicItemData[oldTopic.parent].topics).length === 0) {
      delete newTopicItemData[oldTopic.parent];
    }
    const newTopicItem = {
      ...updatedTopicItem,
      data: newTopicItemData
    }
    setUpdatedTopicItem(newTopicItem)
  }
  
  const changeOneTopic = (newTopic: SelectedTopic, oldTopic: SelectedTopic) => {
    const newTopicItemData: any = {...updatedTopicItem}.data;
    if (!newTopicItemData || !oldTopic.child || !newTopic.child) {
      return;
    }
    if (!newTopicItemData[newTopic.parent]) {
      newTopicItemData[newTopic.parent] = {
        experience: 'unknown',
        topics: {}
      }
    }
    newTopicItemData[newTopic.parent].topics[newTopic.child] = {...newTopicItemData[oldTopic.parent].topics[oldTopic.child]}
    delete newTopicItemData[oldTopic.parent].topics[oldTopic.child];
    if (Object.keys(newTopicItemData[oldTopic.parent].topics).length === 0) {
      delete newTopicItemData[oldTopic.parent];
    }
    const newTopicItem = {
      ...updatedTopicItem,
      data: newTopicItemData
    }
    setUpdatedTopicItem(newTopicItem)
  }

  const submitTopicItem = async () => {
    try {
      const result = await sendRequest({
        method: 'post',
        url: '/network/items/confirm',
        body: {
          topicItemId: topicItem.id,
          data: updatedTopicItem.data
        }
      })
      console.log(result)
      message.success('Topic item submitted!');
    } catch (err: any) {
      message.error(err.message);
    }
  }

  let addPlaceholder = 'Add Parent Category';
  if (selectedAccountTopic) {
    addPlaceholder = `Add Child of "${selectedAccountTopic.parent}"`;
  }

  return (
    <TopicManagementItemContainer>
      <StandardCard
        title="Topic Item"
      >
        <div
          className="topic-item"
        >
          {
            topicItem.rawInput &&
              <div
                className="topic-item-summaries"
              >
                <Paragraph>
                  <pre>{topicItem.rawInput}</pre>
                </Paragraph>
              </div>
          }
          {
            topicItem.summaries &&
              <div
                className="topic-item-summaries"
              >
                <Paragraph>
                  {
                    areSummariesExpanded &&
                      <pre>{JSON.stringify(topicItem.summaries, null, 2)}</pre>
                  }
                  {
                    !areSummariesExpanded &&
                      <pre>{JSON.stringify(topicItem.summaries)}</pre>
                  }
                </Paragraph>
                {
                  !areSummariesExpanded &&
                    <Button
                      type="link"
                      size="small"
                      onClick={() => setAreSummariesExpanded(true)}
                    >
                      Expand Summaries
                    </Button>
                }
                <Divider
                  style={{
                    marginTop: 12
                  }}
                />
              </div>
          }
          <div
            className="topic-item-add-category"
          >
            <Space.Compact style={{ width: '400px' }}>
              <Input
                placeholder={addPlaceholder}
                value={addedCategoryValue}
                onChange={updateAddedCategoryValue}
              />
              <Button
                type="primary"
                onClick={addCategory}
              >
                Add
              </Button>
            </Space.Compact>
          </div>
          <div
            className="topic-item-trees"
          >
            <TopicManagementNetworkTree
              networkTopics={networkTopics}
              addedCategories={addedCategories}
              onSelectNetworkTopic={selectNetworkTopic}
            />
            <TopicManagementItemTree
              topicItem={updatedTopicItem}
              originalTopicItem={topicItem}
              onSelectItemTopic={selectItemTopic}
            />
            <TopicManagementItemChangeStatus
              selectedAccountTopic={selectedAccountTopic}
              selectedNewTopic={selectedNewTopic}
              onConfirmUpdate={confirmTopicUpdate}
            />
          </div>
          <Divider />
          <div
            className="topic-item-submit"
          >
            <StandardButton
              onClick={submitTopicItem}
            >
              Submit Topics
            </StandardButton>
          </div>
        </div>
      </StandardCard>
    </TopicManagementItemContainer>
  )

}

export const TopicManagementDataTree = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .topic-tree-title {
    margin-bottom: 16px;
    font-weight: bold;
  }
`

interface TopicManagementNetworkTreeProps {
  networkTopics: NetworkTopics;
  addedCategories: MinimalNetworkTopicMap;
  onSelectNetworkTopic: (topic: string) => void;
}

const updatedTopicStyle = {
  color: colors.blue,
  fontWeight: 'bold'
};

export const TopicManagementNetworkTree = ({networkTopics, addedCategories, onSelectNetworkTopic}: TopicManagementNetworkTreeProps) => {

  const selectNetworkTopic = ([key]: any) => {
    onSelectNetworkTopic(key);
  }

  const treeData: DataNode[] = [];

  Object.entries(addedCategories).forEach(([parentKey, parentTopic]) => {
    if (networkTopics.data[parentKey]) {
      return;
    }
    const treeDataNode: DataNode = {
      title: parentKey,
      key: parentKey,
      style: updatedTopicStyle,
      children: []
    }
    if (parentTopic.topics) {
      const children = Object.entries(parentTopic.topics).map(([childKey]) => {
        return {
          title: childKey,
          key: `${parentKey}__${childKey}`,
          style: updatedTopicStyle,
        }
      })
      treeDataNode.children = children;
    }
    treeData.push(treeDataNode)
  })

  Object.entries(networkTopics.data).forEach(([parentKey, parentTopic]) => {
    const treeDataNode: DataNode = {
      title: parentKey,
      key: parentKey,
      children: []
    }
    const children: any = [];
    if (addedCategories[parentKey]?.topics) {
      // @ts-ignore
      Object.entries(addedCategories[parentKey].topics).forEach(([childKey, childTopic]) => {
        children.push({
          title: childKey,
          key: `${parentKey}__${childKey}`,
          style: updatedTopicStyle
        })
      })
    }
    if (parentTopic.topics) {
      Object.entries(parentTopic.topics).forEach(([childKey, childTopic]) => {
        children.push({
          title: childKey,
          key: `${parentKey}__${childKey}`
        })
      })
      treeDataNode.children = children;
    }
    treeData.push(treeDataNode)
  })

  return (
    <TopicManagementDataTree>
      <div
        className="topic-tree-title"
      >
        Account Topics
      </div>
      <Tree
        treeData={treeData}
        onSelect={selectNetworkTopic}
      />
    </TopicManagementDataTree>
  )

}

interface TopicManagementItemTreeProps {
  topicItem: NetworkTopicItem;
  originalTopicItem: NetworkTopicItem;
  onSelectItemTopic: (topic: string) => void;
}

export const TopicManagementItemTree = ({topicItem, onSelectItemTopic}: TopicManagementItemTreeProps) => {

  const [isUpdatingItem, setIsUpdatingItem] = useState(false);

  useEffect(() => {
    if (isUpdatingItem) {
      setIsUpdatingItem(false);
    }
  }, [isUpdatingItem])

  useEffect(() => {
    if (!isUpdatingItem) {
      setIsUpdatingItem(true);
    }
  }, [topicItem])

  const selectItemTopic = ([key]: any) => {
    onSelectItemTopic(key);
  }

  if (!topicItem.data) {
    return (
      <TopicManagementDataTree>
        <div
          className="topic-tree-title"
        >
          New Topics
        </div>
        <div>
          No data
        </div>
      </TopicManagementDataTree>
    )
  }

  const treeData: DataNode[] = [];

  Object.entries(topicItem.data).forEach(([parentKey, parentTopic]) => {
    const treeDataNode: DataNode = {
      title: parentKey,
      key: parentKey,
      children: []
    }
    if (parentTopic.topics) {
      const children: any = [];
      Object.entries(parentTopic.topics).forEach(([childKey]) => {
        const treeChildDataNode: DataNode = {
          title: childKey,
          key: `${parentKey}__${childKey}`
        }
        children.push(treeChildDataNode);
      })
      treeDataNode.children = children;
    }
    treeData.push(treeDataNode)
  })

  return (
    <TopicManagementDataTree>
      <div
        className="topic-tree-title"
      >
        New Topics
      </div>
      {
        !isUpdatingItem &&
          <Tree
            treeData={treeData}
            defaultExpandAll
            defaultExpandParent
            autoExpandParent
            onSelect={selectItemTopic}
          />
      }
    </TopicManagementDataTree>
  )

}

const TopicManagementChangeStatusContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  .change-status-text {
    color: ${colors.darkGray};
    text-align: center;
  }

  .topic-status-topic {
    color: ${colors.primary}
  }

  .change-status-confirm {
    margin-top: 32px;
  }
`

interface TopicManagementItemChangeStatusProps {
  selectedAccountTopic?: SelectedTopic;
  selectedNewTopic?: SelectedTopic;
  onConfirmUpdate: () => void;
}

export const TopicManagementItemChangeStatus = ({selectedAccountTopic, selectedNewTopic, onConfirmUpdate}: TopicManagementItemChangeStatusProps) => {

  const isUpdateReady = selectedAccountTopic && selectedNewTopic;

  let updateType: ItemChangeUpdateType | undefined;
  if (isUpdateReady) {
    updateType = getItemChangeUpdateType(selectedAccountTopic, selectedNewTopic);
  }

  let updateStatusMessage = (
    <div>
      Choose/create an account topic and a new topic to update
    </div>
  );
  if (updateType) {
    switch(updateType) {
      case ItemChangeUpdateType.MOVE_ALL:
        updateStatusMessage = (
          <div>
            Move all<br /><span className="topic-status-topic">{selectedNewTopic?.parent}</span><br />topics to<br /><span className="topic-status-topic">{selectedAccountTopic?.parent}</span>
          </div>
        )
        break;
      case ItemChangeUpdateType.MOVE_ONE:
        updateStatusMessage = (
          <div>
            Move<br /><span className="topic-status-topic">{selectedNewTopic?.parent} -&gt; {selectedNewTopic?.child}</span><br />topic to<br /><span className="topic-status-topic">{selectedAccountTopic?.parent} -&gt; {selectedNewTopic?.child}</span>
          </div>
        )
        break;
      case ItemChangeUpdateType.CHANGE_ONE:
        updateStatusMessage = (
          <div>
            Change<br /><span className="topic-status-topic">{selectedNewTopic?.parent} -&gt; {selectedNewTopic?.child}</span><br />topic to<br /><span className="topic-status-topic">{selectedAccountTopic?.parent} -&gt; {selectedAccountTopic?.child}</span>
          </div>
        )
        break;
    }
  }

  return (
    <TopicManagementChangeStatusContainer>
      <div
        className="change-status-text"
      >
        {updateStatusMessage}
      </div>
      {
        isUpdateReady &&
          <div
            className="change-status-confirm"
          >
            <StandardButton
              size="small"
              type="primary"
              onClick={onConfirmUpdate}
            >
              Confirm Update
            </StandardButton>
          </div>
      }
    </TopicManagementChangeStatusContainer>
  )

}
