import { createBrowserRouter } from "react-router-dom"

import { AuthPageContainer } from "@src/components/containers/auth";
import { AuthPage } from "@src/views/auth/auth";
import { MainPageContainer } from "@src/components/containers/main";
import { HiveChatPage } from "@src/views/hive/chat/hive-chat";
import { ImportPage } from "@src/views/import/import";
import { ImportConfigsPage } from "@src/views/import/import-configs";
import { ActivatePage } from "@src/views/auth/activate";
import { SlackPage } from "@src/views/integrations/slack";
import { AnalyzePage } from "@src/views/analyze/analyze";
import { TopicManagementPage } from "@src/views/topics/topics";
import { SubjectManagementPage } from "@src/views/subjects/subjects";
import { IntegrationsPage } from "@src/views/integrations/integrations";
import { DebugPage } from "@src/views/debug/debug";
import { LogPage } from "@src/views/log/log";
import { PromptEditorPage } from "@src/views/prompts/prompts";

export const AppRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthPageContainer />,
    children: [
      {
        path: '',
        element: <AuthPage />,
      },
    ]
  },
  {
    path: '/activate',
    element: <AuthPageContainer />,
    children: [
      {
        path: '',
        element: <ActivatePage />,
      },
    ]
  },
  {
    path: '/hive',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <HiveChatPage />,
      },
    ]
  },
  {
    path: '/import',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <ImportPage />,
      },
      {
        path: 'configs',
        element: <ImportConfigsPage />,
      },
    ]
  },
  {
    path: '/analyze',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <AnalyzePage />,
      }
    ]
  },
  {
    path: '/topics',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <TopicManagementPage />,
      }
    ]
  },
  {
    path: '/subjects',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <SubjectManagementPage />,
      }
    ]
  },
  {
    path: '/integrations',
    element: <AuthPageContainer />,
    children: [
      {
        path: '',
        element: <IntegrationsPage />,
      },
      {
        path: 'slack',
        element: <SlackPage />,
      },
    ]
  },
  {
    path: '/debug',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <DebugPage />,
      }
    ]
  },
  {
    path: '/log',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <LogPage />,
      }
    ]
  },
  {
    path: '/prompts',
    element: <MainPageContainer />,
    children: [
      {
        path: '',
        element: <PromptEditorPage />,
      }
    ]
  }
]);