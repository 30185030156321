import { paragon } from '@useparagon/connect';
import { IntegrationPlatformItem, IntegrationPlatformProps } from "../integrations";
import { message } from 'antd';



export const IntegrationSharepoint = (props: IntegrationPlatformProps) => {

  const connectToSharepoint = async () => {
    paragon.connect('sharepoint', {
      onSuccess: async () => {
        if (props.onConnect) {
          await props.onConnect('sharepoint');
          message.success('Connected to SharePoint!');
        }
      },
      onError: (error) => {}
    });
  }

  return (
    <IntegrationPlatformItem
      name="sharepoint"
      label="SharePoint"
      isEnabled={props.paragonUser?.integrations['sharepoint']?.enabled || false}
      connect={connectToSharepoint}
    />
  )
}