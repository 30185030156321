import { createContext } from "react";

export interface UserState {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  accountId: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

type UserContextType = [undefined | UserState, (userData: Partial<UserState>) => void];

export const UserContext = createContext<UserContextType>([undefined, () => {}]);