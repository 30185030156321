import { Account } from "@src/types/account.types";
import { sendRequest } from "@src/utils/request/request.utils";
import { Card, message } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components"

const slackScopes = [
  'chat:write',
  'channels:read',
  'groups:history',
  'groups:write',
  'groups:read',
  'groups:write.invites',
  'groups:write.topic',
  'im:history',
  'im:read',
  'im:write',
  'team:read',
  'users:read',
  'users.profile:read',
  'users:read.email'
]

const slackRedirectUrl = `${process.env.REACT_APP_WEB_URL}/integrations/slack`;

const slackAuthUrl = `https://slack.com/oauth/v2/authorize?`
  + `client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}`
  + `&redirect_uri=${slackRedirectUrl}`
  + `&scope=${slackScopes.join(',')}`
  + `&user_scope=`

const slackSvg = (
  <svg
    style={{
      height: '20px',
      width: '20px',
      marginRight: '12px'
    }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.8 122.8"
  >
    <path
      d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path><path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path><path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path><path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
      fill="#ecb22e"
    />
  </svg>
)

interface SlackIntegrationParams {
  teamId: string;
  botToken: string;
  botId?: string;
  botUserId?: string;
}

const SlackPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 32px;
  align-items: center;
`

export const SlackPage = () => {

  const [searchParams] = useSearchParams();

  const [tokenCode] = useState(searchParams.get('code'));
  const [isConnectionComplete, setIsConnectionComplete] = useState(false);

  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    if (tokenCode) {
      sendTokenExchange();
    }
    fetchAccount();
  }, [])

  const fetchAccount = async () => {
    const result = await sendRequest({
      method: 'get',
      url: '/account'
    })
    setAccount(result);
  }

  const sendTokenExchange = async () => {

    if (!tokenCode || !process.env.REACT_APP_SLACK_CLIENT_ID || !process.env.REACT_APP_SLACK_CLIENT_SECRET) {
      return;
    }

    const data = new URLSearchParams();
    data.append('client_id', process.env.REACT_APP_SLACK_CLIENT_ID);
    data.append('client_secret', process.env.REACT_APP_SLACK_CLIENT_SECRET);
    data.append('code', tokenCode);
    data.append('redirect_uri', slackRedirectUrl);

    const result = await fetch('https://slack.com/api/oauth.v2.access', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString()
    });
    const response = await result.json();
    
    if (!response.ok) {
      message.error(`Slack integration failed: ${response.error}`);
      return;
    }

    const params: SlackIntegrationParams = {
      teamId: response.team.id,
      botToken: response.access_token,
      botUserId: response.bot_user_id
    }

    const updatedAccount = await setAccountIntegration(params);

    setAccount(updatedAccount);

    setIsConnectionComplete(true);

  }

  const setAccountIntegration = async (params: SlackIntegrationParams) => {
    const result = await sendRequest({
      method: 'post',
      url: '/account/integrations/slack',
      body: params
    })

    return result;
  }

  if (!account) {
    return (
      <SlackPageContainer>
        <Card>
          Loading account...
        </Card>
      </SlackPageContainer>
    )
  }

  if (account?.integrations?.slack) {
    return (
      <SlackPageContainer>
        <Card>
          Connected to Slack!
        </Card>
      </SlackPageContainer>
    )
  }

  return (
    <SlackPageContainer>
      {
        !tokenCode &&
          <a
            href={encodeURI(slackAuthUrl)}
            style={{
              alignItems: 'center',
              color: '#000',
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '4px',
              display: 'inline-flex',
              fontFamily: 'Lato, sans-serif',
              fontSize: '16px',
              fontWeight: '600',
              height: '48px',
              justifyContent: 'center',
              textDecoration: 'none',
              width: '236px',
            }}
          >
            {slackSvg}Connect to Slack
          </a>
      }
      {
        tokenCode &&
          <Card>
            {
              !isConnectionComplete &&
                <div>
                  Connecting to Slack...
                </div>
            }
            {
              isConnectionComplete &&
              <div>
                Connected!
              </div>
            }
          </Card>
      }
    </SlackPageContainer>
  )

}