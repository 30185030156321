import styled from "styled-components/macro"
import { HiveChatMessages } from "./hive-chat-messages"
import { HiveChatInput } from "./hive-chat-input"
import { Affix, Card, message } from "antd"
import { useContext, useEffect, useState } from "react"
import { HiveConnectionsMessage, HiveMessage, HiveTextMessage } from "@src/types/hive/chat.types"
import { sleep } from "@src/utils/sleep/sleep"
import { sendRequest } from "@src/utils/request/request.utils"
import { UserContext } from "@src/context/user"
import { useNavigate } from "react-router-dom"

const HiveChatPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`

export const HiveChatPage = () => {

  const navigate = useNavigate();

  let sentMessage = false;

  const [user] = useContext(UserContext);

  const [messages, setMessages] = useState<Array<HiveMessage>>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [canSendMessage, setCanSendMessage] = useState(true);

  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (user && !user.isSuperAdmin && user.accountId !== '9UKbSn9kbSqrFiT2yZcR') {
      navigate('/integrations');
    }
  }, [user]);

  useEffect(() => {
    if (!sentMessage) {
      sentMessage = true;
      setTimeout(() => {
        const startMessage: HiveTextMessage = {
          text: 'Hi! I am Sam the bee. Who can I connect you with?',
          isUser: false
        }
        addMessage(startMessage);
      }, 1000)
    }
  }, [])

  const disableInput = () => {
    setIsLoading(true);
    setCanSendMessage(false);
  }

  const enableInput = () => {
    setIsLoading(false);
    setCanSendMessage(true);
  }

  const sendUserMessage = async (text: string) => {
    if (!canSendMessage) {
      return;
    }
    disableInput();
    try {
      const queryMessage: HiveTextMessage = {
        text: text,
        isUser: true
      }
      await addMessage(queryMessage);
      await sleep(500);
      setIsGenerating(true);
      try {
        const response = await sendRequest({
          method: 'post',
          url: '/query/run',
          body: {
            query: text,
            accountId: user?.accountId
          }
        })
        setIsGenerating(false);
        await sleep(500);
        const foundMessage: HiveTextMessage = {
          text: 'I found some people that can help with that!',
          isUser: false
        }
        addMessage(foundMessage);
        await sleep(750);
        const connectionsMessage: HiveConnectionsMessage = {
          connections: response.results
        }
        addMessage(connectionsMessage);
        // show connections
      } catch (err: any) {
        message.error(err.message);
      }
      setIsGenerating(false);
    } catch (err) {
      console.log(err);
    }
    enableInput();
  }

  const addMessage = async (message: HiveMessage) => {
    setMessages((messages) => {
      const newMessages = [...messages];
      newMessages.push(message);
      return newMessages;
    })
  }

  if (!user) {
    return (
      <HiveChatPageContainer>
        <Card
          style={{
            width: 600,
            maxWidth: '90%',
            margin: '0 auto'
          }}
        >
          Loading PairUp Hive...
        </Card>
      </HiveChatPageContainer>
    )
  }

  return (
    <HiveChatPageContainer>
      <HiveChatMessages
        messages={messages}
        isGenerating={isGenerating}
        addMessage={addMessage}
      />
      <Affix offsetBottom={0}>
        <HiveChatInput
          sendMessage={sendUserMessage}
          isLoading={isLoading}
          isDisabled={!canSendMessage}
        />
      </Affix>
    </HiveChatPageContainer>
  )

}