export const colors = {
  primary: '#2A9D8F',
  blue: '#1C748C',
  yellow: '#FFD23F',
  red: '#F47458',
  // grayscale
  black: '#1D1D1D',
  darkGray: '#7B7B7B',
  mediumGray: '#D9D9D9',
  lightGray: '#F1F2F5',
  extraLightGray: '#F8F9FA',
  white: '#FFFFFF'
}

export const fonts = {
  secondary: "'Quicksand', sans-serif",
  tertiary: "'Bitter', sans-serif",
  monospace: "'Cousine', monospace"
}

export const gradients = {
  linear: (fromColor: string, toColor: string) => {
    return `linear-gradient(to right, ${fromColor}, 66.6%, ${toColor})`
  }
}