export interface ImportConfig {
  id: string;
  name: string;
  options: DataImportOptions;
  accountId?: string;
  createdById?: string;
  createdAt: Date;
}

export interface DataImportOptions {
  fields: ImportConfigFields;
  handlers: Array<ImportConfigHandler>;
}

export interface ImportConfigFields {
  [field: string]: ImportConfigField;
}

export interface ImportConfigField {
  column: number;
  required: boolean;
}

export interface ImportConfigHandler {
  type: DataImportHandlerType;
  template?: string;
  customPromptInstructions?: string;
  userIdentifierType?: UserIdentifierType;
  userInfoType?: UserInfoType;
}

export enum DataImportHandlerType {
  SUMMARY = 'summary',
  USER_IDENTIFIER = 'identifier', // used to identify user
  USER_INFO = 'info' // used to add user info like name, email, etc.
}

export enum UserIdentifierType {
  EMAIL = 'email',
  ID = 'id'
}

export enum UserInfoType {
  FULL_NAME = 'fullName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName'
}

export const DEFAULT_HANDLER: ImportConfigHandler = {
  type: DataImportHandlerType.SUMMARY,
  template: ''
}