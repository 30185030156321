import { StandardButton } from "@src/components/standard/button/button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ImportPageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`

export const ImportPage = () => {

  const navigate = useNavigate();

  const viewConfigs = async () => {
    navigate('/import/configs');
  }

  return (
    <ImportPageContainer>
      <StandardButton
        onClick={viewConfigs}
      >
        View Import Configs
      </StandardButton>
    </ImportPageContainer>
  )

}