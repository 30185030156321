import { ConfigToolbar } from "@src/components/config/config-editor"
import { SlateElementChild, SlateTextElement } from "@src/components/config/config-editor.types"
import { ConfigElement, withParams } from "@src/components/config/config-editor.utils"
import { ConfigParam, ConfigParams } from "@src/components/config/config.types"
import { StandardButton } from "@src/components/standard/button/button"
import { colors, fonts } from "@src/config/styles/variables"
import { useState } from "react"
import { Descendant, Transforms, createEditor } from "slate"
import { withHistory } from "slate-history"
import { Editable, Slate, withReact } from "slate-react"
import styled from "styled-components"
import { DataImportHandlerType, ImportConfigFields, ImportConfigHandler, UserIdentifierType, UserInfoType } from "./import-config.types"
import { Divider, Input, Select } from "antd"

const ConfigInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ConfigInputEditorContainer = styled.div`
  border: 1px solid ${colors.mediumGray};
  border-radius: 2px;

  .config-editor-input {
    padding: 16px;
    background-color: ${colors.lightGray};
    font-family: ${fonts.monospace};
  }
`

interface ConfigInputProps {
  handler: ImportConfigHandler;
  fields: ImportConfigFields;
  index: number;
  saveHandler: (index: number, handler: ImportConfigHandler) => void;
}

export const ConfigInput = (props: ConfigInputProps) => {

  const {handler, fields, index, saveHandler} = props;

  const params: ConfigParams = {};

  const [handlerType, setHandlerType] = useState<DataImportHandlerType>(handler.type);
  const [handlerTemplate, setHandlerTemplate] = useState(handler.template);
  const [
    handlerCustomPromptInstructions,
    setHandlerCustomPromptInstructions
  ] = useState(handler.customPromptInstructions)
  const [
    handlerUserIdentifierType,
    setHandlerUserIdentifierType
  ] = useState(handler.userIdentifierType)
  const [
    handlerUserInfoType,
    setHandlerUserInfoType
  ] = useState(handler.userInfoType)

  Object.entries(fields).forEach(([key, field]) => {
    params[key] = {
      key: key,
      description: key,
      label: key,
      display: key
    }
  })

  const [editor] = useState(() => {
    return (
      withParams(
        withHistory(
          withReact(
            createEditor()
          )
        )
      )
    )
  })

  let initialValue: Array<Descendant> = [
    {
      type: 'text',
      children: [
        {
          text: ''
        }
      ],
    }
  ];

  const parseHandlerTemplateForEditor = (template: string) => {
    const values: Array<SlateElementChild> = [];
    const paramSections = template.split('*|');
    paramSections.forEach((paramSection) => {
      const innerSections = paramSection.split('|*');
      if (innerSections.length === 1) {
        values.push({
          text: innerSections[0]
        })
      }
      if (innerSections.length === 2) {
        const sectionParam = innerSections[0];
        values.push({
          type: 'param',
          param: {
            key: sectionParam,
            label: sectionParam,
            description: sectionParam,
            display: sectionParam
          },
          children: [{
            text: ''
          }]
        })
        values.push({
          text: innerSections[1]
        })
      }
    })
    return values;
  }

  if (handlerTemplate && handlerTemplate?.trim().length) {
    initialValue = [{
      type: 'text',
      children: parseHandlerTemplateForEditor(handlerTemplate)
    }]
  }

  const insertParam = (param: ConfigParam) => {
    editor.insertParam(param);
    Transforms.deselect(editor);
    editor.insertNode({
      text: ' '
    })
  }

  const handleTemplateChange = () => {}
  
  const handleTypeChange = (value: DataImportHandlerType) => {
    setHandlerType(value);
  }

  const handleCustomPromptChange = (prompt: string) => {
    setHandlerCustomPromptInstructions(prompt);
  }
  
  const handleUserIdentifierChange = (value: UserIdentifierType) => {
    setHandlerUserIdentifierType(value);
  }
  
  const handleInfoTypeChange = (value: UserInfoType) => {
    setHandlerUserInfoType(value);
  }

  const saveConfigHandler = async () => {
    try {
      const template = getHandlerTemplate();
      const handlerData: ImportConfigHandler = {
        type: handlerType,
        template: template
      }
      if (
        handlerType === DataImportHandlerType.SUMMARY &&
        handlerCustomPromptInstructions?.trim().length
      ) {
        handlerData.customPromptInstructions = handlerCustomPromptInstructions;
      }
      if (handlerType === DataImportHandlerType.USER_IDENTIFIER) {
        handlerData.userIdentifierType = handlerUserIdentifierType;
      }
      if (handlerType === DataImportHandlerType.USER_INFO) {
        handlerData.userInfoType = handlerUserInfoType;
      }
      saveHandler(index, handlerData);
    } catch (err) {
      console.log(err);
    }
  }
  
  const getHandlerTemplate = () => {
    let handlerText: string = '';
    const root = editor.children[0] as SlateTextElement;
    root.children.forEach((item: any) => {
      if (item.param) {
        handlerText += `*|${item.param.key}|*`;
      } else {
        handlerText += item.text;
      }
    })
    return handlerText.trim();
  }

  return (
    <ConfigInputContainer>
      <ConfigInputType
        defaultValue={handlerType}
        onChange={handleTypeChange}
      />
      {
        handlerType === DataImportHandlerType.USER_IDENTIFIER &&
          <ConfigInputIdentifierType
            onChange={handleUserIdentifierChange}
            defaultValue={handlerUserIdentifierType}
          />
      }
      {
        handlerType === DataImportHandlerType.USER_INFO &&
          <ConfigInputInfoType
            onChange={handleInfoTypeChange}
            defaultValue={handlerUserInfoType}
          />
      }
      <ConfigInputEditorContainer>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={handleTemplateChange}
        >
          <ConfigToolbar
            params={params}
            usedParams={[]}
            insertParam={insertParam}
          />
          <Editable
            className="config-editor-input"
            autoFocus
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            renderElement={(props) => {
              const elementProps = {
                ...props,
                editor: editor
              }
              return <ConfigElement {...elementProps} />
            }}
          />
        </Slate>
      </ConfigInputEditorContainer>
      {
        handlerType === DataImportHandlerType.SUMMARY &&
          <ConfigInputCustomPrompt
            defaultValue={handlerCustomPromptInstructions}
            onChange={handleCustomPromptChange}
          />
      }
      <StandardButton
        size="small"
        style={{
          width: '200px'
        }}
        onClick={saveConfigHandler}
      >
        Save Handler
      </StandardButton>
      <Divider />
    </ConfigInputContainer>
  )

}

const ConfigInputItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

interface ConfigInputTypeProps {
  defaultValue: DataImportHandlerType;
  onChange: (handlerType: DataImportHandlerType) => void;
}
const ConfigInputType = ({onChange, defaultValue}: ConfigInputTypeProps) => {

  const options = Object.values(DataImportHandlerType).map((type) => {
    return {
      value: type,
      label: type
    }
  })

  return (
    <ConfigInputItemContainer>
      <div>
        Handler Type
      </div>
      <Select
        style={{ width: 150 }}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </ConfigInputItemContainer>
  )

}


interface ConfigInputCustomPromptProps {
  defaultValue: string | undefined;
  onChange: (prompt: string) => void;
}
const ConfigInputCustomPrompt = ({onChange, defaultValue}: ConfigInputCustomPromptProps) => {

  const handleChange = (event: any) => {
    onChange(event.target.value);
  }

  return (
    <ConfigInputItemContainer>
      <div>
        Custom Prompt Instructions
      </div>
      <Input.TextArea
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </ConfigInputItemContainer>
  )

}

interface ConfigInputIdentifierTypeProps {
  defaultValue: UserIdentifierType | undefined;
  onChange: (identifierType: UserIdentifierType) => void;
}
const ConfigInputIdentifierType = ({onChange, defaultValue}: ConfigInputIdentifierTypeProps) => {

  const options = Object.values(UserIdentifierType).map((type) => {
    return {
      value: type,
      label: type
    }
  })

  return (
    <ConfigInputItemContainer>
      <div>
        User Identifier Type
      </div>
      <Select
        style={{ width: 150 }}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </ConfigInputItemContainer>
  )

}

interface ConfigInputInfoTypeProps {
  defaultValue: UserInfoType | undefined;
  onChange: (infoType: UserInfoType) => void;
}
const ConfigInputInfoType = ({onChange, defaultValue}: ConfigInputInfoTypeProps) => {

  const options = Object.values(UserInfoType).map((type) => {
    return {
      value: type,
      label: type
    }
  })
  
  return (
    <ConfigInputItemContainer>
      <div>
        User Info Type
      </div>
      <Select
        style={{ width: 150 }}
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </ConfigInputItemContainer>
  )

}
