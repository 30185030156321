import { paragon } from '@useparagon/connect';
import { IntegrationPlatformItem, IntegrationPlatformProps } from "../integrations";
import { message } from 'antd';



export const IntegrationJira = (props: IntegrationPlatformProps) => {

  const connectToJira = async () => {
    paragon.connect('jira', {
      onSuccess: async () => {
        if (props.onConnect) {
          await props.onConnect('jira');
          message.success('Connected to Jira!');
        }
      },
      onError: (error) => {}
    });
  }

  return (
    <IntegrationPlatformItem
      name="jira"
      label="Jira"
      isEnabled={props.paragonUser?.integrations['jira']?.enabled || false}
      connect={connectToJira}
    />
  )
}