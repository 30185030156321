import styled from "styled-components"
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { PromptTemplate } from "./prompts.types";

const PromptTemplateDiffViewContainer = styled.div`
  
`

interface PromptTemplateDiffViewProps {
  template: PromptTemplate;
  updatedTemplate?: string;
}

export const PromptTemplateDiffView = ({template, updatedTemplate}: PromptTemplateDiffViewProps) => {

  return (
    <PromptTemplateDiffViewContainer>
      {
        !updatedTemplate &&
          <div>
            No changes
          </div>
      }
      {
        updatedTemplate &&
          <ReactDiffViewer
            oldValue={template.template}
            newValue={updatedTemplate}
            splitView={false}
            compareMethod={DiffMethod.LINES}
          />
      }
    </PromptTemplateDiffViewContainer>
  )

}