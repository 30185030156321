import styled from "styled-components"
import { createEditor, Descendant, Transforms } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { useState } from "react"
import { colors, fonts } from "@src/config/styles/variables"
import { StandardButton } from "@src/components/standard/button/button"
import { ConfigSaveData, SlateElementChild, SlateParamElement, SlateText, SlateTextElement } from "@src/components/config/config-editor.types"
import { Configuration, ConfigurationDataItem, ConfigParam, ConfigParams, ConfigType } from "@src/components/config/config.types"
import { ConfigElement, withParams, withSingleLine } from "@src/components/config/config-editor.utils"
import { withHistory } from "slate-history"
import { Popconfirm, Tag } from "antd"
import { CheckOutlined, QuestionCircleOutlined } from "@ant-design/icons"

const ConfigEditorContainer = styled.div`
  border: 1px solid ${colors.mediumGray};
  border-radius: 2px;

  .config-editor-input {
    padding: 16px;
    background-color: ${colors.lightGray};
    font-family: ${fonts.monospace};
  }
`

const ConfigSaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 16px;
`

interface ConfigEditorProps {
  type: ConfigType,
  config?: Configuration;
  clonedConfig?: Configuration;
  onSave: (data: Array<ConfigSaveData>) => Promise<void>;
  onArchive: () => Promise<void>;
}

export type ParamsState = [Array<string>, (params: Array<string>) => void];

export const ConfigEditor = ({type, onSave, onArchive, config, clonedConfig}: ConfigEditorProps) => {

  const [usedParams, setUsedParams]: ParamsState = useState<Array<string>>([]);

  const [isSaving, setIsSaving] = useState(false);

  const params: any = {};

  const [editor] = useState(() => {
    return (
      withParams(
        withSingleLine(
          withHistory(
            withReact(
              createEditor()
            )
          )
        )
      )
    )
  })

  const insertParam = (param: ConfigParam) => {
    editor.insertParam(param);
    Transforms.deselect(editor);
    editor.insertNode({
      text: ' '
    })
  }

  const handleChange = (value: Array<Descendant>) => {
    const element = value[0] as SlateTextElement;
    const items = element.children;
    const paramsInUse: Array<string> = [];
    items.forEach((item: SlateElementChild) => {
      const paramElement = item as SlateParamElement;
      if (paramElement.type && paramElement.type === 'param') {
        paramsInUse.push(paramElement.param.key);
      }
    })
    setUsedParams(paramsInUse);
  }

  const saveConfig = async () => {
    setIsSaving(true);
    try {
      const configData: Array<ConfigSaveData> = [];
      const root = editor.children[0] as SlateTextElement;
      root.children.forEach((item: SlateElementChild) => {
        if ('param' in item) {
          const paramItem = item as SlateParamElement;
          configData.push({
            type: 'param',
            value: paramItem.param.key
          })
        } else {
          const textItem = item as SlateText;
          configData.push({
            type: 'text',
            value: textItem.text
          })
        }
      })
      await onSave(configData);
    } catch (err) {
      console.log(err);
    }
    setIsSaving(false)
  }

  const archiveConfig = async () => {
    setIsSaving(true);
    try {
      await onArchive()
    } catch (err) {
      console.log(err)
    }
    setIsSaving(false)
  }

  const getInitialValue = (config?: Configuration) => {
    let initialValue: Array<Descendant> = [
      {
        type: 'text',
        children: [
          {
            text: ''
          }
        ],
      }
    ];
    
    if (config) {
      const initialValueChildren: any = [];
      config.data.forEach((item: ConfigurationDataItem) => {
        if (item.type === 'param') {
          initialValueChildren.push({
            type: 'param',
            param: params[item.value],
            children: [{
              text: ''
            }]
          })
        } else {
          initialValueChildren.push({
            text: item.value
          })
        }
      })
      initialValue = [{
        type: 'text',
        children: initialValueChildren
      }]
    }
    return initialValue;
  }

  let initialValue = getInitialValue(config || clonedConfig);

  return (
    <>
      <ConfigParamList
        params={params}
        usedParams={usedParams}
      />
      <ConfigEditorContainer>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={handleChange}
        >
          <ConfigToolbar
            params={params}
            usedParams={usedParams}
            insertParam={insertParam}
          />
          <Editable
            className="config-editor-input"
            autoFocus
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            renderElement={(props) => {
              const elementProps = {
                ...props,
                editor: editor
              }
              return <ConfigElement {...elementProps} />
            }}
          />
        </Slate>
      </ConfigEditorContainer>
      <ConfigSaveContainer>
        <StandardButton
          size="small"
          type="primary"
          ghost
          onClick={saveConfig}
          disabled={isSaving}
        >
          Save Configuration
        </StandardButton>
        {
          config &&
            <Popconfirm
              placement="topRight"
              title="Archive Configuration"
              description="Are you sure you want to archive this configuration?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={archiveConfig}
              okButtonProps={{
                danger: true
              }}
              okText="Archive"
              cancelText="Cancel"
            >
              <StandardButton
                size="small"
                danger
                disabled={isSaving}
              >
                Archive
              </StandardButton>
            </Popconfirm>
        }
      </ConfigSaveContainer>
    </>
  )

}

const ConfigParamListContainer = styled.div`
  margin-bottom: 32px;

  .param-list-title {
    font-family: ${fonts.tertiary};
    font-weight: 600;
    margin-bottom: 8px;
  }

  .param-list-item {
    display: flex;
    margin-bottom: 4px;
  }
`

interface ConfigParamListProps {
  params: ConfigParams;
  usedParams: Array<string>;
}

export const ConfigParamList = ({params, usedParams}: ConfigParamListProps) => {

  const paramList = Object.values(params);

  return (
    <ConfigParamListContainer>
      <div
        className="param-list-title"
      >
        Fields
      </div>
      {
        paramList.map((param) => {
          const isUsed = usedParams.includes(param.key);
          let tagColor;
          if (isUsed) {
            tagColor = colors.primary;
          }

          return (
            <div
              className="param-list-item"
            >
              <div
                className="param-list-item-label"
              >
                <Tag
                  color={tagColor}
                >
                  {param.label}
                </Tag>
              </div>
              <div
                className="param-list-item-description"
              >
                {param.description}
              </div>
            </div>
          )
        })
      }
    </ConfigParamListContainer>
  )

}

const ConfigToolbarContainer = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid ${colors.mediumGray};

  .ant-btn {
    padding: 3px 6px !important;
  }

  .config-toolbar-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .config-toolbar-section-title {
      font-size: 14px;
      font-family: ${fonts.secondary};
      font-weight: 700;
    }

    .config-toolbar-section-actions {
      display: flex;
      gap: 8px;
    }
  }
`

interface ConfigToolbarProps {
  params: ConfigParams;
  usedParams: Array<string>;
  insertParam: (param: ConfigParam) => void
}

export const ConfigToolbar = ({params, usedParams, insertParam}: ConfigToolbarProps) => {

  const paramList: Array<ConfigParam> = Object.values(params);

  return (
    <ConfigToolbarContainer>
      <div
        className="config-toolbar-section"
      >
        <div
          className="config-toolbar-section-title"
        >
          Insert Param:
        </div>
        <div
          className="config-toolbar-section-actions"
        >
          {
            paramList.map((param) => {
              const isUsed = usedParams.includes(param.key);
              let icon;
              if (isUsed) {
                icon = <CheckOutlined />;
              }
              return (
                <StandardButton
                  key={param.key}
                  size="small"
                  type="primary"
                  ghost
                  icon={icon}
                  disabled={isUsed}
                  onClick={() => insertParam(param)}
                >
                  {param.label}
                </StandardButton>
              )
            })
          }
        </div>
      </div>
    </ConfigToolbarContainer>
  )

}
