import { getCurrentUserToken } from "@src/utils/firebase/firebase.utils";

interface RequestParams {
  method: 'get' | 'post';
  url: string;
  headers?: Headers;
  body?: any;
  allowCredentials?: boolean;
}

interface FileRequestParams extends RequestParams {
  file: File;
  filename: string;
}

interface RequestHeaders {
  'Authorization'?: string;
  'Content-Type'?: string;
}

const METHOD_MAP = {
  post: 'POST',
  get: 'GET',
  delete: 'DELETE',
  put: 'PUT'
}

const BASE_URL = process.env.REACT_APP_API_URL;

export const sendRequest = async (params: RequestParams) => {
  const headers: RequestHeaders = {
    'Content-Type': 'application/json'
  };
  const token = await getCurrentUserToken();
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  // URL Params
  let urlParams = '';
  if (params.method === 'get' && params.body) {
    urlParams = getUrlParams(params.body);
    params.body = null;
  }
  // Request Body
  let requestBody;
  if (params.body) {
    requestBody = JSON.stringify(params.body);
  }
  const requestHeaders: any = headers;
  const result = await fetch(BASE_URL + params.url + urlParams, {
    method: METHOD_MAP[params.method],
    headers: requestHeaders,
    credentials: params.allowCredentials ? 'include' : 'omit',
    body: requestBody
  });
  let response;
  try {
    response = await result.json();
  } catch (err) {
    response = null;
  }
  if (!result.ok) {
    throw new Error(response.message);
  }
  return response;
}

const getUrlParams = (params: any) => {
  let paramString = '';
  const keys = Object.keys(params);
  if (keys.length) {
    paramString = '?'
    keys.forEach((key, index) => {
      paramString += key + '=' + encodeURIComponent(params[key])
      if (index < keys.length - 1) {
        paramString += '&'
      }
    });
  }
  return paramString;
}

export const sendFileRequest = async (params: FileRequestParams) => {
  let result;
  const headers: RequestHeaders = {};
  const token = await getCurrentUserToken();
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  const data = new FormData();
  if (params.file) {
    data.append('file', params.file, params.filename)
  }
  if (params.body) {
    for (const key of Object.keys(params.body)) {
      data.append(key, params.body[key])
    }
  }
  const requestHeaders: any = headers;
  result = await fetch(BASE_URL + params.url, {
    method: METHOD_MAP[params.method],
    headers: requestHeaders,
    body: data
  });
  const response = await result.json();
  if (!result.ok) {
    console.warn(response)
    throw new Error(response.message);
  }
  return response;
}