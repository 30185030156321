// SubjectGroup

export interface SubjectGroup {
  id: string;
  status: SubjectGroupStatus;
  description: string;
  topic: SubjectGroupTopic;
  action: SubjectActionType;
  subjectSessionId: string;
  createdByIds: Array<string>;
  joinedByIds: Array<string>;
  accountId: string;
  createdAt: Date;
}

export interface SubjectGroupTopic {
  parent: string;
  child: string;
}

export enum SubjectGroupStatus {
  PENDING = 'pending',
  CONFIRMED = 'confirmed'
}

// Subject

export interface Subject {
  id: string;
  description: string;
  action: SubjectActionType;
  status: SubjectStatus;
  subjectInputId: string;
  subjectGroupId?: string;
  subjectSessionId: string;
  userId: string;
  accountId: string;
  createdAt: Date;
}

export enum SubjectStatus {
  PENDING = 'pending',
  READY = 'ready',
  COMPLETE = 'complete'
}

export enum SubjectActionType {
  ASK = 'ask',
  DISCUSS = 'discuss',
  ACTIVITY = 'activity',
  MEET = 'meet'
}

// SubjectInput

export interface SubjectInput {
  id: string;
  text: string;
  action: SubjectActionType;
  status: SubjectInputStatus;
  subjectSessionId: string;
  userId: string;
  accountId: string;
  createdAt: Date;
}

export enum SubjectInputStatus {
  PENDING = 'pending',
  READY = 'ready',
  COMPLETE = 'complete'
}

// SubjectSession

export interface SubjectSession {
  id: string;
  status: SubjectSessionStatus;
  accountId: string;
  startsAt: Date;
  createdAt: Date;
}

export enum SubjectSessionStatus {
  PENDING = 'pending',
  GATHERING = 'gathering',
  MATCHING = 'matching',
  COMPLETE = 'complete'
}